import { GameplayRenderer, LetterboxRenderingType } from '@dorian/gameplay-renderer';
import { GameUIStyle } from '@dorian/gameplay-renderer/dist/models/GameUIStyle';
import { useEffect, useRef, useState } from 'react';
import { useAvatarResolver } from '../../../../../dorian-shared/hooks/useAvatarResolver/useAvatarResolver';
import { RuntimeEnv, getRuntimeEnv } from '../../../../../getRuntimeEnv';

const runtimeEnv = getRuntimeEnv();
const envLabel = runtimeEnv === RuntimeEnv.Prod ? 'prod' : 'staging';

function getLetterboxSettings(gameUIStyle: GameUIStyle) {
  const isLetterboxForArcanaActive = gameUIStyle === GameUIStyle.Arcana;

  if (isLetterboxForArcanaActive) {
    return {
      letterboxRenderingType: LetterboxRenderingType.Letterbox,
      letterboxViewportSettings: {
        viewportSize: {
          width: 1080,
          height: 1920,
        },
        viewportBgColor: 0x000000,
      },
    };
  }

  return {
    letterboxRenderingType: LetterboxRenderingType.Standard,
    letterboxViewportSettings: undefined,
  };
}

export function useGameplayRenderer(
  width: number,
  height: number,
  gameUIStyle: GameUIStyle,
) {
  const avatarResolver = useAvatarResolver(envLabel);
  const containerRef = useRef(null);
  const [gameplayRenderer, setGameplayRenderer] = useState<GameplayRenderer>();
  useEffect(() => {
    (async () => {
      if (!containerRef.current) {
        return;
      }
      if (!avatarResolver) {
        return;
      }
      if (gameplayRenderer) {
        return;
      }

      const { letterboxRenderingType, letterboxViewportSettings } = getLetterboxSettings(gameUIStyle);

      const gameplayRendererInstance = new GameplayRenderer({
        parentElement: containerRef.current,
        domLayerStyle: 'pointer-events: none;',
        width,
        height,
        maxHeightOfCanvas: height,
        avatarResolver,
        viewPortSize: {
          width,
          height,
        },
        localAssetsPath: `${process.env.PUBLIC_URL}/`,
        // TODO: probably we should have it on engine rather than env rather than here or on AWS
        mouseEventsEnabled: false,
        styles: {
          alias: gameUIStyle,
        },
        isMultiplayer: true,
        letterboxRenderingType,
        letterboxViewportSettings,
      });
      await gameplayRendererInstance.waitUntilReady();
      setGameplayRenderer(gameplayRendererInstance);
    })();
    // gameUIStyle change should not trigger recreation of GR, we handle it in other useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarResolver, gameplayRenderer, width, height]);

  useEffect(() => {
    if (!gameplayRenderer) {
      return;
    }

    gameplayRenderer.updateStyleAlias(gameUIStyle);
  }, [gameplayRenderer, gameUIStyle]);

  return { containerRef, gameplayRenderer };
}
