import { useEffect, useState } from 'react';
import { useAuthKeyPair } from '../../../../providers/AuthProvider';
import { useStreamContext } from '../../../../providers/StreamProvider';
import { StreamEvent } from '../../../../services/streamService/streamService';
import { appId, appToken } from '../../utils/apiUtils';

export function useVideosByPlayerIdV2(multiplayerId: number) {
  const [videosByPlayerId, setVideosByPlayerId] = useState({});
  const authKeyPair = useAuthKeyPair();
  const streamService = useStreamContext();

  useEffect(() => {
    const usersChangedVideoCallback = streamService.addListener(StreamEvent.UsersChangedVideo, () => {
      setVideosByPlayerId(streamService.getRemoteUsers('video'));
    });
    streamService.join(multiplayerId, appId, appToken, authKeyPair.playerId).then(() => {
      streamService.setClientRole('audience');
      streamService.subscribeEvents();
    });
    return () => {
      streamService.unsubscribeAll();
      if (usersChangedVideoCallback) {
        streamService.removeListener(StreamEvent.UsersChangedVideo, usersChangedVideoCallback);
      }
      streamService.leave();
    };
  }, [authKeyPair.playerId, multiplayerId, streamService]);

  return { videosByPlayerId };
}
