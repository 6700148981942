import { useEffect, useState } from 'react';
import { useStreamContext } from '../../../../providers/StreamProvider';

export function useStreamAudios() {
  const [isMuted, setIsMuted] = useState(true);
  const streamService = useStreamContext();

  useEffect(() => {
    if (isMuted) {
      streamService.isAudioAccess = false;
      streamService.stopAudioAll();
    } else {
      streamService.isAudioAccess = true;
      streamService.playAudioAll();
    }
  }, [isMuted, streamService]);

  return {
    isMuted,
    onMuteChange: setIsMuted,
    shouldMuteButtonVisible: streamService.isJoined,
  };
}
