import { formatSeconds } from './formatSeconds';
import { useCountdown } from './useCountdown';

interface CountdownProps {
  initialSeconds: number
  onCountdownEnd?: () => void
}

export function Countdown(props: CountdownProps) {
  const { initialSeconds, onCountdownEnd } = props;
  const secondsLeft = useCountdown(
    initialSeconds,
    onCountdownEnd,
  );
  return (
    <span>
      {formatSeconds(secondsLeft)}
    </span>
  );
}
