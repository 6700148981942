import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';
import { useDownloadDorianURL } from '../../../hooks/useDownloadDorianURL';
import cs from './DownloadButton.module.css';

export function DownloadButton() {
  const downloadDorianURL = useDownloadDorianURL();
  return (
    <div
      className={cs.container}
    >
      <PrimaryButton
        onClick={() => window.open(downloadDorianURL, '_blank', 'noopener,noreferrer')}
      >
        DOWNLOAD DORIAN
      </PrimaryButton>
    </div>
  );
}
