export enum StreamState {
    None = 'None',
    Dialogue = 'Dialogue',
    Choice = 'Choice',
    ChoiceResult = 'ChoiceResult',
    Text = 'Text',
    Data = 'Data',
    Input = 'Input',
    Ending = 'Ending',
    Results = 'Results',
    Quiz = 'Quiz',
    PlayerSelect = 'PlayerSelect',
    Narrator = 'Narrator',
    Texting = 'Texting',
    Thinking = 'Thinking',
    LoginPrompt = 'LoginPrompt',
    ChooseFtue = 'ChooseFtue',
    FtueEnd = 'FtueEnd',
    Reaction = 'Reaction',
    Categories = 'Categories',
    Rewards = 'Rewards',
}
