import clsx from 'clsx';
import { HTMLProps } from 'react';
import cs from './SecondaryButton.module.css';

type SecondaryButtonProps = Omit<HTMLProps<HTMLButtonElement>, 'type'>

export function SecondaryButton(props: SecondaryButtonProps) {
  const {
    className, children, ...otherProps
  } = props;
  return (
    <button
      type="button"
      className={clsx(cs.button, className)}
      {...otherProps}
    >
      {children}
    </button>
  );
}
