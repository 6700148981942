import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetDorianAppSection } from '../../components/GetDorianAppSection/GetDorianAppSection';
import { StreamInfoSectionV2 } from '../../components/StreamInfoSectionV2/StreamInfoSectionV2';
import { StreamInfoSectionV2Small } from '../../components/StreamInfoSectionV2Small/StreamInfoSectionV2Small';
import { getSizeConfig } from '../../dorian-shared/getSizeConfig';
import { ParticipantsSocketMessage } from '../../dorian-shared/types/stream-socket/ParticipantsSocketMessage';
import { SetNextEpisodeSocketMessage } from '../../dorian-shared/types/stream-socket/SetNextEpisodeSocketMessage';
import {
  SomeoneConnectedToRoomSocketMessage,
} from '../../dorian-shared/types/stream-socket/SomeoneConnectedToRoomSocketMessage';
import { MultiplayerProvider } from '../../providers/MultiPlayerProvider';
import { MultiplayerEvent, multiplayerService } from '../../services/multiplayerService/multiplayerService';
import { useEpisodeInfo } from '../../services/s3EngineObjectsCache';
import { useStreamPageStyles } from '../StreamPage/StreamPageStyles';
import { fetchMultiplayerStatus } from './fetchMultiplayerStatus';
import { StreamV2 } from './Stream/StreamV2';
import { BaseParticipant } from './types/BaseParticipant';

export interface ActiveStreamPageV2Props {
  multiplayerId?: number;
  eventTitle?: string;
}

export function ActiveStreamPageV2(props: ActiveStreamPageV2Props) {
  const { multiplayerId: multiplayerIdFromProps, eventTitle } = props;

  const [episodeId, setEpisodeId] = useState('');
  const [host, setHost] = useState<BaseParticipant | undefined>();
  const [viewsCount, setViewsCount] = useState(0);
  const [isStreamExist, setIsStreamExist] = useState<boolean | undefined>();

  const params = useParams();
  const multiplayerId = multiplayerIdFromProps ?? Number(params.multiplayerId);
  const streamPageStyles = useStreamPageStyles();

  const episodeInfo = useEpisodeInfo(episodeId ?? '');

  useEffect(() => {
    fetchMultiplayerStatus(multiplayerId).then((newMultiplayerStatus) => {
      setEpisodeId(newMultiplayerStatus.episodeId);
      setIsStreamExist(Boolean(newMultiplayerStatus.episodeId));
    });
  }, [multiplayerId]);

  useEffect(() => {
    document.body.classList.add(streamPageStyles.body);
    return () => {
      document.body.classList.remove(streamPageStyles.body);
    };
  }, [streamPageStyles.body]);

  const handleParticipants = useCallback((data: ParticipantsSocketMessage) => {
    const { participants } = data;
    const newHost = participants.find((participant) => participant.host);
    setHost(newHost);
  }, []);

  const handleSomeoneConnectedToRoom = useCallback((data: SomeoneConnectedToRoomSocketMessage) => {
    const { totalAppCount, totalWebCount } = data;

    const newTotalAppCount = totalAppCount != null ? totalAppCount : 0;
    const newTotalWebCount = totalWebCount != null ? totalWebCount : 0;
    setViewsCount(newTotalAppCount + newTotalWebCount);
  }, []);

  const handleSetNextEpisode = useCallback((data: SetNextEpisodeSocketMessage) => {
    const { episodeId } = data;
    setEpisodeId(episodeId);
  }, []);

  useEffect(() => {
    multiplayerService.addListener(MultiplayerEvent.Participants, handleParticipants);
    multiplayerService.addListener(MultiplayerEvent.SomeoneConnectedToRoom, handleSomeoneConnectedToRoom);
    multiplayerService.addListener(MultiplayerEvent.SetNextEpisode, handleSetNextEpisode);
    return () => {
      multiplayerService.removeListener(MultiplayerEvent.Participants, handleParticipants);
      multiplayerService.removeListener(MultiplayerEvent.SomeoneConnectedToRoom, handleSomeoneConnectedToRoom);
      multiplayerService.removeListener(MultiplayerEvent.SetNextEpisode, handleSetNextEpisode);
    };
  }, [handleParticipants, handleSetNextEpisode, handleSomeoneConnectedToRoom]);

  const { gameplayWidth, gameplayHeight } = getSizeConfig();

  return (
    <>
      <div className={streamPageStyles.getDorianApp}>
        <GetDorianAppSection />
      </div>
      <div className={streamPageStyles.streamContainerWithPaddings}>
        <div className={streamPageStyles.streamContainer} style={{ width: gameplayWidth, height: gameplayHeight }}>
          {isStreamExist !== undefined && (
          <MultiplayerProvider>
            <StreamV2
              gameplayWidth={gameplayWidth}
              gameplayHeight={gameplayHeight}
              episodeId={episodeId}
              multiplayerId={multiplayerId}
            />
          </MultiplayerProvider>
          )}
        </div>
      </div>
      {episodeInfo && host && (
      <>
        <div className={streamPageStyles.streamInfo}>
          <StreamInfoSectionV2
            eventTitle={eventTitle ?? episodeInfo.title}
            hostName={host?.username}
            hostAvatarUrl={host?.avatarUrl ?? ''}
          />
        </div>
        <div className={streamPageStyles.streamInfoSmall}>
          <StreamInfoSectionV2Small
            eventTitle={eventTitle ?? episodeInfo.title}
            hostName={host?.username}
            hostAvatarUrl={host?.avatarUrl ?? ''}
            viewsCount={viewsCount}
          />
        </div>
      </>
      )}
    </>
  );
}
