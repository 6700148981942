import axios from 'axios';
import { RuntimeEnv, getRuntimeEnv } from '../getRuntimeEnv';

const ROOT_DOMAIN = 'test.4dorian.net';
const DEFAULT_PORPOISE_NAMESPACE = 'dev';
const PORPOISE_NAMESPACE_MATCHER = new RegExp(`^[\\w]+\\.([\\w]+)\\.${ROOT_DOMAIN.replace(/\./g, '\\.')}$`);
const porpoisePath = (sub: string, namespace: string) => `https://${sub}.${namespace}.${ROOT_DOMAIN}`;

const getPorpoiseSubdomain = (sub: string, _hostname?: string): string => {
  const namespace = PORPOISE_NAMESPACE_MATCHER
    .exec(_hostname || window?.location?.hostname || '')?.[1] || DEFAULT_PORPOISE_NAMESPACE;
  return porpoisePath(sub, namespace);
};

enum MultiplayerServerEnvs {
  Local = 'Local',
  Unstable1 = 'Unstable1',
  Unstable2 = 'Unstable2',
  Staging = 'Staging',
  Tactical = 'Tactical',
  Prod = 'Prod',
  Porpoise = 'Porpoise',
}

const serverMultiplayerUrlByEnvLabel = {
  [MultiplayerServerEnvs.Local]: 'http://localhost:3003',
  [MultiplayerServerEnvs.Unstable1]: 'http://ec2-18-224-212-245.us-east-2.compute.amazonaws.com:3003',
  [MultiplayerServerEnvs.Unstable2]: 'http://ec2-18-222-255-227.us-east-2.compute.amazonaws.com:3003',
  [MultiplayerServerEnvs.Staging]: 'http://ec2-18-216-176-218.us-east-2.compute.amazonaws.com:3003',
  [MultiplayerServerEnvs.Tactical]: 'http://ec2-18-216-176-218.us-east-2.compute.amazonaws.com:3003',
  [MultiplayerServerEnvs.Prod]: 'https://prod.chat.4dorian.net',
  [MultiplayerServerEnvs.Porpoise]: getPorpoiseSubdomain('mp'),
};

const runtimeEnv = getRuntimeEnv();
const envLabelByRuntimeEnv = {
  [RuntimeEnv.Prod]: MultiplayerServerEnvs.Prod,
  [RuntimeEnv.Stg]: MultiplayerServerEnvs.Staging,
  [RuntimeEnv.Dev]: MultiplayerServerEnvs.Staging,
  [RuntimeEnv.Local]: MultiplayerServerEnvs.Staging,
  [RuntimeEnv.Porpoise]: MultiplayerServerEnvs.Porpoise,
};
const envLabel = envLabelByRuntimeEnv[runtimeEnv];

export const serverMultiplayerUrl = serverMultiplayerUrlByEnvLabel[envLabel];

export const multiplayerServer = axios.create({
  baseURL: `${serverMultiplayerUrl}/v5`,
});
