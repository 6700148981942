import { CustomCharacterVisualProperties } from '../character/CustomCharacterVisualProperties';
import { ExpressionValue } from '../character/ExpressionValue';
import { StreamState } from './StreamState';

export enum CharacterPlacementSide {
    Left = 'left',
    Right = 'right',
}

interface Author {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  fullname: string;
  image: string;
  // username?: any;
  // about?: any;
}

interface Setting {
  summary: string;
  chapter: number;
  uuid: string;
  revision: number;
  genre: string;
  author: Author;
  coAuthors: Author[];
  scene: string;
  // editors: any[];
}

export interface Scene {
  title: string;
  alias: string;
  audio?: {
      url: string
  }
}

type Scenes = Record<string, Scene>

export interface Character {
    name: string;
    side: string;
    isPlayer: boolean;
    imageAlias: string;
    lockArt: boolean;
    lockName: boolean;
    hasForceAuthorsChoiceName: boolean;
    imageName: string;
    properties?: CustomCharacterVisualProperties;
}

type Characters = Record<string, Character>

export type StepCharacter = {
    alias: string;
    expression: ExpressionValue;
    characterPlacementSide?: CharacterPlacementSide
};

export interface Modifier {
    id: string;
}

export enum AnswerType {
    Free = 'free',
    Paid = 'paid',
    Romantic = 'romantic',
    Passive = 'passive',
    // Active = 'active'
    Erotic = 'erotic',
}
export interface Answer {
    text: string;
    uuid: string;
    goto: {
        branch: string;
    };
    type: AnswerType;
    data?: {
        level: number
    };
    modifiers?: Modifier[];
}

export interface Step {
    index: number;
    type: StreamState;
    text: string;
    goto?: {
      branch: string;
    };
    character?: StepCharacter
    answers?: Answer[]
}

interface Branch {
    id: string;
    description: string;
    uuid: string;
    scene: string;
    x: number;
    y: number;
    steps: Step[];
}

type Branches = Record<string, Branch>

export interface EpisodeContentResponseData {
  setting: Setting;
  // settings_display: SettingsDisplay;
  scenes: Scenes;
  characters: Characters;
  choice_indices: string[];
  branches: Branches;
  templateId: string;
}
