import { DialogProps } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogContentText from '@mui/material/DialogContentText/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DownloadButton } from '../../../components/DownloadButton/DownloadButton';

export type HostLeftDialogProps = DialogProps

export function HostLeftDialog(props: HostLeftDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        The host has left this stream.
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Click the button below to download Dorian and join another stream!
        </DialogContentText>
        <DownloadButton />
      </DialogContent>
    </Dialog>
  );
}
