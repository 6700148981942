import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGlobalStateContext } from '../../../providers/GlobalStateProvider';
import { BaseParticipant } from '../types/BaseParticipant';
import { StreamState } from '../types/StreamState';
import { ErrorBoundary } from './Gameplay/ErrorBoundary/ErrorBoundary';
import { Gameplay } from './Gameplay/Gameplay';
import { useChoiceMemoryVariablesValuesByName } from './hooks/useChoiceMemoryVariablesValuesByName';
import { useVideosByPlayerIdV2 } from './hooks/useVideosByPlayerIdV2';
import { Participants } from './Participants/Participants';
import cs from './Stream.module.css';
import { StreamFooter } from './StreamFooter/StreamFooter';
import { StreamHeader } from './StreamHeader/StreamHeader';
import { StreamStartCountdown } from './StreamStartCountdown/StreamStartCountdown';
import { StreamStatus } from './StreamStatus/StreamStatus';
import { sortParticipants } from './streamUtils';
import { useStreamDataV2 } from './useStreamDataV2';
import { VotingCountdown } from './VotingCountdown/VotingCountdown';
import { WaitingPanelV2 } from './WaitingPanelV2/WaitingPanelV2';

interface StreamPropsV2 {
  gameplayWidth: number
  gameplayHeight: number
  episodeId: string
  multiplayerId: number
}

export function StreamV2(props: StreamPropsV2) {
  const {
    gameplayWidth, gameplayHeight, episodeId, multiplayerId,
  } = props;

  const {
    streamData,
    startStreamAfterCountdown,
    participantsByPlayerId,
    viewsCount,
    largeVideoPlayerId,
    episodeContent,
    currentEpisodeId,
  } = useStreamDataV2(multiplayerId, episodeId);

  const {
    videosByPlayerId,
  } = useVideosByPlayerIdV2(multiplayerId);

  const isLargeVideoOn = Boolean(largeVideoPlayerId);

  const [searchParams] = useSearchParams();
  const isFeaturedStream = Boolean(searchParams.get('isFeaturedView'));
  const navigate = useNavigate();
  const globalState = useGlobalStateContext();

  const hostName = useMemo(
    () => Object
      .values(participantsByPlayerId)
      .find((participant: BaseParticipant) => participant.host)?.username ?? '',
    [participantsByPlayerId],
  );
  const visibleParticipants = useMemo(
    () => Object
      .values(participantsByPlayerId)
      .filter((participant) => participant.canBroadcast)
      .sort(sortParticipants)
      .slice(0, 5),
    [participantsByPlayerId],
  );

  const navigateToFeaturedStreams = useCallback(() => {
    navigate('/featured-streams/?version=2', { replace: true });
  }, [navigate]);

  const onDataError = useCallback(() => {
    globalState.increaseFeaturedStreamsReloadCount();
    if (isFeaturedStream) {
      navigateToFeaturedStreams();
    }
  }, [isFeaturedStream, navigateToFeaturedStreams, globalState]);

  const choiceMemoryVariablesValuesByName = useChoiceMemoryVariablesValuesByName(
    currentEpisodeId,
    streamData.state === StreamState.InProgress ? streamData.advance.variables : {},
    onDataError,
  );

  useEffect(() => {
    if (!isFeaturedStream) {
      return;
    }
    if (streamData.state !== StreamState.StreamIsOver) {
      return;
    }

    navigateToFeaturedStreams();
  }, [streamData.state, isFeaturedStream, navigateToFeaturedStreams]);

  return (
    <>
      {streamData.state === StreamState.InProgress && (
        <ErrorBoundary>
          <div className={clsx({ [cs.invisible]: isLargeVideoOn })}>
            <Gameplay
              episodeUuid={currentEpisodeId}
              episodeContent={episodeContent}
              branchName={streamData.advance.branch}
              stepIndex={streamData.advance.index}
              width={gameplayWidth}
              height={gameplayHeight}
              playerName={hostName} // temp requirements till we have authentication
              choiceMemoryVariablesValuesByName={choiceMemoryVariablesValuesByName}
            />
          </div>
        </ErrorBoundary>
      )}
      <div className={cs.streamControlsContainer} style={{ width: gameplayWidth, height: gameplayHeight }}>
        <StreamHeader>
          <StreamStatus
            viewsCount={viewsCount}
            hostName={hostName}
          />
        </StreamHeader>
        <Participants
          videosByPlayerId={videosByPlayerId}
          participants={visibleParticipants}
          largeVideoPlayerId={largeVideoPlayerId}
        />
        <VotingCountdown />
        <div id="LargeVideoPortalContainer" className={cs.largeVideoContainer}>
          {!isLargeVideoOn && (
            <>
              {streamData.state === StreamState.InitialCountdown && (
                <StreamStartCountdown
                  onCountdownEnd={() => setTimeout(startStreamAfterCountdown, 1000)}
                />
              )}
              {streamData.state === StreamState.PreStreamLobby && (
              <WaitingPanelV2 episodeId={episodeId} message="Waiting for others to join..." />
              )}
              {streamData.state === StreamState.AfterEpisodeLobby && (
              <WaitingPanelV2 episodeId={episodeId} message="Choosing another episode..." />
              )}
            </>
          )}
        </div>
        <StreamFooter />
      </div>
    </>
  );
}
