import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAuthKeyPairFromLocalStorage } from '../../providers/AuthProvider';
import { useGlobalStateContext } from '../../providers/GlobalStateProvider';
import { mediaServer } from '../../services/mediaServer';
import { multiplayerServer } from '../../services/multiplayerServer';
import { fetchMultiplayerStatus } from '../ActiveStreamPage/fetchMultiplayerStatus';
import { useStreamPageStyles } from '../StreamPage/StreamPageStyles';
import cs from './FeaturedStreamPage.module.css';
import { FeaturedStreamVideo } from './FeaturedStreamVideo/FeaturedStreamVideo';

/* eslint-disable jsx-a11y/media-has-caption */

type FeaturedStream = {
  sessionId: number,
  started?: boolean,
  startDate?: string | null,
}

type FeaturedStreamLobbiesResult = {
  lobbies: FeaturedStream[],
  roomCap: number,
}
type FallbackUrlResult = {
  ok: boolean,
  data: {
    videoUrl: string,
  },
}

function selectFeaturedStream(lobbies: FeaturedStream[]) {
  // TODO: add some logic to pick the best stream to display
  return lobbies[0];
}

async function getActiveStream(testStreamId: number | null = null): Promise<FeaturedStream | null> {
  try {
    const authKeyPair = getAuthKeyPairFromLocalStorage();
    // old endpoints: getLiveLobbiesForStripWeb, getMultiplayerLobbiesWeb
    const res = await multiplayerServer.post<FeaturedStreamLobbiesResult>('/getMultiplayerLobbiesForStripWeb', {
      ...authKeyPair,
    });

    if (res.data && res.data.lobbies && res.data.lobbies.length > 0) {
      return selectFeaturedStream(res.data.lobbies);
    }
  } catch (error) {
    console.error('Missing lobbies for featured stream');
  }

  // to make testing easier
  if (testStreamId) {
    return {
      sessionId: testStreamId,
      started: true,
    };
  }

  return null;
}

async function getAlternativeStreamUrl(): Promise<string | null> {
  try {
    const res = await mediaServer.get<FallbackUrlResult>('/streams/featured');
    if (res && res.data.ok && res.data.data) {
      return res.data.data.videoUrl;
    }
    return null;
  } catch (error) {
    return null;
  }
}

function loadNextVideoWithDelay(
  callback: (isForced: boolean) => void,
  currentTimeout?: ReturnType<typeof setTimeout>,
  isForced = false,
) {
  if (currentTimeout) {
    clearTimeout(currentTimeout);
  }
  return setTimeout(() => {
    callback(isForced);
  }, 5000);
}

export function FeaturedStreamPage() {
  const [videoUrl, setVideoUrl] = useState('');
  const [videoNum, setVideoNum] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const globalState = useGlobalStateContext();
  const isStreamFound = useRef<number | undefined>();
  const nextVideoTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [searchParams] = useSearchParams();
  const webClientVersion = Number(searchParams.get('version')) ?? 1;
  const testStreamId = Number(searchParams.get('testStreamId')) ?? null;

  const loadNextVideo = useCallback((isForced = false) => {
    // stop loading if there is already a video loaded
    if (!isForced && videoUrl !== '') {
      return;
    }

    setIsLoading(true);
    getAlternativeStreamUrl().then((loadedVideoUrl) => {
      if (loadedVideoUrl === null) {
        nextVideoTimeout.current = loadNextVideoWithDelay(loadNextVideo, nextVideoTimeout.current, isForced);
        return;
      }

      setVideoUrl(loadedVideoUrl);
      setVideoNum((currentVideoNum) => currentVideoNum + 1);
    }).catch(() => {
      nextVideoTimeout.current = loadNextVideoWithDelay(loadNextVideo, nextVideoTimeout.current, isForced);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [videoUrl]);

  const gotoStream = useCallback((streamId: number) => {
    isStreamFound.current = streamId;

    let streamUrl = `/streams/${streamId}/true`;
    if (webClientVersion === 2) {
      streamUrl = `/stream?multiplayerId=${streamId}&isFeaturedView=true`;
    }
    navigate(streamUrl, { replace: true });
  }, [navigate, webClientVersion]);

  const selectStream = useCallback((stream: FeaturedStream) => {
    const { sessionId: streamId } = stream;

    if (streamId === 0) {
      loadNextVideo();
      return;
    }

    // fetch so we don't send users to the stream if it is ended already or can't really load it
    fetchMultiplayerStatus(streamId).then((multiplayerStatus) => {
      if (multiplayerStatus && multiplayerStatus.storyId > 0) {
        gotoStream(streamId);
      } else {
        loadNextVideo();
      }
    });
  }, [gotoStream, loadNextVideo]);

  const loadStreams = useCallback(() => {
    if (isStreamFound.current) {
      return;
    }

    getActiveStream(testStreamId).then((stream) => {
      if (!stream) {
        loadNextVideo();
        return;
      }
      selectStream(stream);
    });
  }, [selectStream, loadNextVideo, testStreamId]);

  const onVideoEnded = () => {
    loadNextVideo(true);
  };

  const streamPageStyles = useStreamPageStyles();

  useEffect(() => {
    document.body.classList.add(streamPageStyles.body);
    return () => {
      document.body.classList.remove(streamPageStyles.body);
    };
  }, [streamPageStyles.body]);

  useEffect(() => {
    // how often we check streams when fallback video is displayed
    const refreshTime = 60 * 1000;
    const streamsInterval = setInterval(loadStreams, refreshTime);

    // if there are any reloads (ex: when error on the stream occur)
    // prevent user to get back to stream right away
    if (globalState.featuredStreamsReloadCount === 0) {
      loadStreams();
    } else {
      loadNextVideo(true);
    }

    return () => {
      if (streamsInterval) {
        clearInterval(streamsInterval);
      }

      if (nextVideoTimeout.current) {
        clearTimeout(nextVideoTimeout.current);
      }
    };
  }, [loadStreams, globalState, loadNextVideo]);

  return (
    <div className={cs.container}>
      {(isLoading || !videoUrl) && (
        <div className={cs.loader}>LOADING</div>
      )}
      {videoUrl && !isLoading
        ? (
          <FeaturedStreamVideo
            videoUrl={videoUrl}
            videoNum={videoNum}
            onVideoEnded={onVideoEnded}
          />
        )
        : null}
    </div>
  );
}
