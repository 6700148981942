import { useCallback, useEffect, useState } from 'react';
import { ParticipantsSocketMessage } from '../../../dorian-shared/types/stream-socket/ParticipantsSocketMessage';
import { SetLargeVideoSocketMessage } from '../../../dorian-shared/types/stream-socket/SetLargeVideoSocketMessage';
import { useMultiplayerContext } from '../../../providers/MultiPlayerProvider';
import { MultiplayerEvent } from '../../../services/multiplayerService/multiplayerService';

export function useLargeVideoPlayerId() {
  const [largeVideoPlayerId, setLargeVideoPlayerId] = useState('');
  const multiplayerService = useMultiplayerContext();

  const handleSetLargeVideo = useCallback((data: SetLargeVideoSocketMessage) => {
    const { playerId, largeVideo: isLargeVideoOn } = data;
    if (!isLargeVideoOn) {
      setLargeVideoPlayerId('');
    } else {
      setLargeVideoPlayerId(playerId);
    }
  }, []);

  const handleParticipants = useCallback((data: ParticipantsSocketMessage) => {
    const { participants } = data;
    const participantWithLargeVideo = participants.find((participant) => participant.largeVideo);
    if (participantWithLargeVideo) {
      setLargeVideoPlayerId(participantWithLargeVideo.playerId);
    } else {
      setLargeVideoPlayerId('');
    }
  }, []);

  useEffect(() => {
    multiplayerService.addListener(MultiplayerEvent.SetLargeVideo, handleSetLargeVideo);
    multiplayerService.addListener(MultiplayerEvent.Participants, handleParticipants);
    return () => {
      multiplayerService.removeListener(MultiplayerEvent.SetLargeVideo, handleSetLargeVideo);
      multiplayerService.removeListener(MultiplayerEvent.Participants, handleParticipants);
    };
  }, [handleParticipants, handleSetLargeVideo, multiplayerService]);
  return largeVideoPlayerId;
}
