import AgoraRTC, { ClientRole, IAgoraRTCClient, IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import { logger } from '../loggerService/loggerService';

export enum StreamEvent {
  UserSubscribeAudio = 'user-published-audio',
  UserSubscribeVideo = 'user-published-video',
  UserUnsubscribeAudio = 'user-unpublished-audio',
  UserUnsubscribeVideo = 'user-unpublished-video',
  UsersChangedVideo = 'users-changed-video',
  UsersChangedAudio = 'users-changed-audio',
}

class StreamService {
  private service: IAgoraRTCClient;

  public isVideoAccess: boolean;

  public isAudioAccess: boolean;

  public isJoined: boolean;

  observer: Map<StreamEvent, Array<()=>void>> = new Map();

  constructor() {
    this.service = AgoraRTC.createClient({ mode: 'live', codec: 'h264' });
    this.isVideoAccess = true;
    this.isAudioAccess = true;
    this.isJoined = false;
  }

  subscribeEvents() {
    logger.info('[Stream] EVENTS subscribe');

    this.service.on('user-published', async (remoteUser, mediaType) => {
      await this.subscribe(remoteUser, mediaType);
    });

    this.service.on('user-unpublished', async (remoteUser, mediaType) => {
      await this.unsubscribe(remoteUser, mediaType);
    });
  }

  async subscribe(remoteUser: IAgoraRTCRemoteUser, mediaType: string) {
    switch (mediaType) {
      case 'audio':
        if (this.isAudioAccess && remoteUser.hasAudio) {
          await this.service.subscribe(remoteUser, mediaType);
          logger.info('[Stream] AUDIO subscribe', remoteUser.uid);
          if (this.isAudioAccess) {
            remoteUser.audioTrack?.play();
            remoteUser.audioTrack?.setVolume(50);
          }
          this.invokeListeners(StreamEvent.UserSubscribeAudio);
          this.invokeListeners(StreamEvent.UsersChangedAudio);
        }
        break;
      case 'video':
        if (this.isVideoAccess && remoteUser.hasVideo) {
          await this.service.subscribe(remoteUser, mediaType);
          logger.info('[Stream] VIDEO subscribe', remoteUser.uid);
          this.invokeListeners(StreamEvent.UserUnsubscribeVideo);
          this.invokeListeners(StreamEvent.UsersChangedVideo);
        }
        break;
      default:
        break;
    }
  }

  async unsubscribe(remoteUser: IAgoraRTCRemoteUser, mediaType: string) {
    switch (mediaType) {
      case 'audio':
        await this.service.unsubscribe(remoteUser, mediaType);
        logger.info('[Stream] AUDIO unsubscribe', remoteUser.uid);
        this.invokeListeners(StreamEvent.UserUnsubscribeAudio);
        this.invokeListeners(StreamEvent.UsersChangedAudio);
        break;
      case 'video':
        await this.service.unsubscribe(remoteUser, mediaType);
        logger.info('[Stream] VIDEO unsubscribe', remoteUser.uid);
        this.invokeListeners(StreamEvent.UserUnsubscribeVideo);
        this.invokeListeners(StreamEvent.UsersChangedVideo);
        break;
      default:
        break;
    }
  }

  unsubscribeAll(type?: string) {
    this.service.remoteUsers.forEach(async (remoteUser) => {
      const unsubscribeVideo = remoteUser.hasVideo && (type === 'video' || type === undefined);
      const unsubscribeAudio = remoteUser.hasAudio && (type === 'audio' || type === undefined);
      if (unsubscribeVideo) {
        await this.unsubscribe(remoteUser, 'video');
      }
      if (unsubscribeAudio) {
        await this.unsubscribe(remoteUser, 'audio');
      }
    });
  }

  async leave() {
    this.unsubscribeAll();
    await this.service.leave();
    this.isJoined = false;
    logger.info('[Stream] LEAVE');
  }

  async join(
    multiplayerId: number,
    appId: string,
    appToken: string | null,
    playerId: string,
  ) {
    const channelId = `room${multiplayerId}`;
    await this.service.join(appId, channelId, appToken, playerId);
    this.isJoined = true;
    logger.info('[Stream] JOIN', playerId);
  }

  async setClientRole(role: ClientRole) {
    await this.service.setClientRole(role);
    logger.info('setClientRole', role);
  }

  getRemoteUsers(type?: string) {
    return this.service.remoteUsers.reduce((acc, remoteUser) => {
      if (remoteUser.hasVideo && (type === 'video' || type === undefined)) {
        return Object.assign(acc, {
          [remoteUser.uid]: remoteUser,
        });
      }
      if (remoteUser.hasAudio && (type === 'audio' || type === undefined) && this.isAudioAccess) {
        return Object.assign(acc, {
          [remoteUser.uid]: remoteUser,
        });
      }
      return acc;
    }, {});
  }

  addListener(streamEvent: StreamEvent, callback: () => void) {
    const listeners = this.observer.get(streamEvent) ?? [];
    if (!listeners.includes(callback)) {
      this.observer.set(streamEvent, [...listeners, callback]);
      logger.info('[Stream] ADD listener', streamEvent);
      return callback;
    }
    return undefined;
  }

  removeListener(streamEvent: StreamEvent, callback: () => void) {
    const listeners = this.observer.get(streamEvent);
    if (listeners) {
      if (listeners.includes(callback)) {
        listeners.filter((listener) => listener !== callback);
        this.observer.set(streamEvent, listeners ?? []);
        logger.info('[Stream] REMOVE listener', streamEvent);
      }
    }
  }

  invokeListeners(streamEvent: StreamEvent) {
    const listeners = this.observer.get(streamEvent);
    listeners?.forEach((listener) => {
      listener?.();
      logger.info('[Stream] INVOKE listener', streamEvent);
    });
  }

  playAudioAll(volume = 50) {
    if (!this.isAudioAccess) {
      return;
    }
    this.service.remoteUsers.forEach(async (remoteUser) => {
      await this.subscribe(remoteUser, 'audio');
      remoteUser.audioTrack?.play();
      remoteUser.audioTrack?.setVolume(volume);
    });
  }

  stopAudioAll() {
    this.service.remoteUsers.forEach(async (remoteUser) => {
      remoteUser.audioTrack?.stop();
      await this.unsubscribe(remoteUser, 'audio');
    });
  }
}

const streamService = new StreamService();

export { streamService };
