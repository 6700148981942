import { Box, Typography } from '@mui/material';
import {
  differenceInSeconds, format, parseISO,
} from 'date-fns';
import { useMemo } from 'react';
import { useCountdown } from '../../../dorian-shared/components/Countdown/useCountdown';
import { Countdown } from './Countdown/Countdown';
import cs from './CountdownSection.module.css';
import startingSoonImg from './startingSoon.png';

interface CountdownSectionProps {
    startDateTime: string
}

export function CountdownSection(props: CountdownSectionProps) {
  const { startDateTime } = props;
  const startDate = parseISO(startDateTime);
  const initialSeconds = useMemo(() => differenceInSeconds(startDate, new Date()), [startDate]);
  const secondsLeft = useCountdown(initialSeconds);
  return secondsLeft > 0 ? (
    <>
      <Typography variant="h1" color="primary.light" mb={2}>
        {format(startDate, 'MMM dd, h:mm aa')}
      </Typography>
      <Typography variant="h1" color="primary.light" mb={3}>
        STARTING IN
      </Typography>
      <Countdown secondsLeft={secondsLeft} />
    </>
  ) : (
    <Box position="relative">
      <img src={startingSoonImg} className={cs.img} alt="STARTING SOON" />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
      >
        <Typography variant="h1" color="primary.light">
          STARTING SOON
        </Typography>
      </Box>
    </Box>
  );
}
