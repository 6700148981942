import { PropsWithChildren, createContext, useContext } from 'react';
import { multiplayerService } from '../services/multiplayerService/multiplayerService';

const multiplayerContext = createContext(multiplayerService);

const { Provider } = multiplayerContext;

export function MultiplayerProvider(props: PropsWithChildren) {
  const { children } = props;
  const multiplayerService = useContext(multiplayerContext);
  return (
    <Provider value={multiplayerService}>
      {children}
    </Provider>
  );
}

export const useMultiplayerContext = () => {
  const context = useContext(multiplayerContext);
  if (context === undefined) {
    throw Error('Use within a MultiplayerProvider');
  }
  return context;
};
