import { Answer } from '@dorian/gameplay-renderer/dist/models/TextBubbleUpdateData';
import { ChoiceMemoryVariablesValuesByName } from '../../../../../../dorian-shared/types/stream-socket/Advance';

export const replaceChoiceMemoryVariables = (
  text: string,
  choiceMemoryVariableValuesByName: ChoiceMemoryVariablesValuesByName,
) => Object.entries(choiceMemoryVariableValuesByName).reduce(
  (finalText, [name, value]) => finalText.replaceAll(`{${name}}`, String(value)),
  text,
);
export const replaceChoiceMemoryVariablesInAnswers = (
  answers: Answer[],
  choiceMemoryVariablesValuesByName: ChoiceMemoryVariablesValuesByName,
) => answers.map((answer) => ({
  ...answer,
  text: replaceChoiceMemoryVariables(answer.text, choiceMemoryVariablesValuesByName),
}));
