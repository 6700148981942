import { PropsWithChildren } from 'react';
import { ReactComponent as ThreeDotsIcon } from '../../../../icons/ThreeDotsIcon.svg';
import { MuteButton } from '../../../components/MuteButton/MuteButton';
import { useDownloadPopup } from '../hooks/useDownloadPopup';
import { useStreamAudios } from '../hooks/useStreamAudios';
import { ReactComponent as DorianLogo } from './DorianLogo.svg';
import cs from './StreamHeader.module.css';

export function StreamHeader(props: PropsWithChildren) {
  const {
    children,
  } = props;

  const showDownloadPopup = useDownloadPopup();
  const { isMuted, onMuteChange, shouldMuteButtonVisible } = useStreamAudios();

  return (
    <div className={cs.container}>
      <div className={cs.leftColumn}>
        {shouldMuteButtonVisible && (
          <MuteButton
            isMuted={isMuted}
            onMuteChange={onMuteChange}
          />
        )}
      </div>
      {children}
      <div className={cs.rightColumn}>
        <div className={cs.logoContainer}>
          <DorianLogo />
        </div>
        <button
          type="button"
          onClick={showDownloadPopup}
          className={cs.streamMenuButton}
        >
          <ThreeDotsIcon />
        </button>
      </div>
    </div>
  );
}
