import { useEffect } from 'react';
import { GetDorianAppSection } from '../../components/GetDorianAppSection/GetDorianAppSection';
import { StreamInfoSectionV2 } from '../../components/StreamInfoSectionV2/StreamInfoSectionV2';
import { StreamInfoSectionV2Small } from '../../components/StreamInfoSectionV2Small/StreamInfoSectionV2Small';
import { getSizeConfig } from '../../dorian-shared/getSizeConfig';
import cs from './StreamPage.module.css';
import { useStreamPageStyles } from './StreamPageStyles';

export interface StreamPageProps {
  coverImageURL: string;
  eventTimeTitle: string;
  eventTitle: string;
  hostUsername: string;
  hostAvatarUrl: string;
  isFinished: boolean;
}

export function StreamPage(props: StreamPageProps) {
  const {
    coverImageURL, eventTitle, eventTimeTitle, hostUsername, hostAvatarUrl, isFinished,
  } = props;

  const streamPageStyles = useStreamPageStyles();

  useEffect(() => {
    document.body.classList.add(streamPageStyles.body);
    return () => {
      document.body.classList.remove(streamPageStyles.body);
    };
  }, [streamPageStyles.body]);

  const { gameplayWidth, gameplayHeight } = getSizeConfig();

  return (
    <>
      <div className={streamPageStyles.getDorianApp}>
        <GetDorianAppSection />
      </div>
      <div
        className={streamPageStyles.streamContainerWithPaddings}
      >
        <div className={streamPageStyles.streamContainer} style={{ width: gameplayWidth, height: gameplayHeight }}>
          <img
            className={isFinished ? cs.coverImageFinished : cs.coverImage}
            src={coverImageURL}
            alt="cover"
          />
        </div>
      </div>
      <div className={streamPageStyles.streamInfo}>
        <StreamInfoSectionV2
          eventTimeTitle={eventTimeTitle}
          eventTitle={eventTitle}
          hostName={hostUsername}
          hostAvatarUrl={hostAvatarUrl}
        />
      </div>
      <div className={streamPageStyles.streamInfoSmall}>
        <StreamInfoSectionV2Small
          eventTimeTitle={eventTimeTitle}
          eventTitle={eventTitle}
          hostName={hostUsername}
          hostAvatarUrl={hostAvatarUrl}
        />
      </div>
    </>
  );
}
