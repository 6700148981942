import { Box, Button } from '@mui/material';
import { EmojiIcon } from '../../../../icons/EmojiIcon';
import { HeartIcon } from '../../../../icons/HeartIcon';
import { ReactComponent as InfoIcon } from '../../../../icons/InfoIcon.svg';
import { MessageIcon } from '../../../../icons/MessageIcon';
import { useDownloadPopup } from '../hooks/useDownloadPopup';
import cs from './StreamFooter.module.css';

export function StreamFooter() {
  const showDownloadPopup = useDownloadPopup();
  return (
    <div className={cs.container}>
      <Button
        className={cs.heartIcon}
        onClick={showDownloadPopup}
      >
        <HeartIcon fontSize="large" />
      </Button>
      <Button
        size="large"
        className={cs.filledButton}
        onClick={showDownloadPopup}
      >
        <MessageIcon />
      </Button>
      <Box ml={2}>
        <Button
          size="large"
          className={cs.filledButton}
          onClick={showDownloadPopup}
        >
          <EmojiIcon />
        </Button>
      </Box>

      <div className={cs.infoContainer}>
        <InfoIcon onClick={showDownloadPopup} className={cs.infoIcon} />
      </div>
    </div>
  );
}
