import ReactQRCode from 'react-qr-code';
import { useDownloadDorianURL } from '../../hooks/useDownloadDorianURL';
import cs from './QRCode.module.css';

export function QRCode() {
  const downloadDorianURL = useDownloadDorianURL();
  return (
    <a
      className={cs.container}
      href={downloadDorianURL}
      rel="noopener noreferrer"
      target="_blank"
    >
      <div className={cs.codeContainer}>
        <ReactQRCode
          size={256}
          className={cs.code}
          value={downloadDorianURL}
          viewBox="0 0 256 256"
        />
      </div>
      <div className={cs.text}>Scan to download</div>
    </a>
  );
}
