import {
  hoursToSeconds, minutesToSeconds, secondsToHours, secondsToMinutes,
} from 'date-fns';
import { DigitsPairsWithLabels } from '../components/DigitsPairsWithLabels/DigitsPairsWithLabels';

function getHoursMinutesSeconds(secondsLeft: number) {
  const wholeHours = secondsToHours(secondsLeft);

  const minutesInSecondsLeft = secondsLeft - hoursToSeconds(wholeHours);

  const wholeMinutes = secondsToMinutes(minutesInSecondsLeft);
  const seconds = minutesInSecondsLeft - minutesToSeconds(wholeMinutes);
  return [
    wholeHours,
    wholeMinutes,
    seconds,
  ] as const;
}

interface HoursMinutesSecondsProps {
    seconds: number
}

export function HoursMinutesSeconds(props: HoursMinutesSecondsProps) {
  const {
    seconds: secondsInTotal,
  } = props;
  const [
    hours,
    minutes,
    seconds,
  ] = getHoursMinutesSeconds(secondsInTotal);

  const digitPairsWithLabels = [
    {
      digit: hours,
      label: 'hour',
    },
    {
      digit: minutes,
      label: 'minute',
    },
    {
      digit: seconds,
      label: 'second',
    },
  ];

  return (
    <DigitsPairsWithLabels
      digitPairsWithLabels={digitPairsWithLabels}
    />
  );
}
