import { Grid } from '@mui/material';
import { memo } from 'react';
import { Units } from './Units/Units';

interface DigitPairWithLabel {
    digit: number,
    label: string,
}

interface DigitsPairsWithLabelsProps {
    digitPairsWithLabels: DigitPairWithLabel[]
}

function InternalDigitsPairsWithLabels(props: DigitsPairsWithLabelsProps) {
  const {
    digitPairsWithLabels,
  } = props;

  return (
    <Grid container spacing={3} color="primary.light" justifyContent="center">
      {digitPairsWithLabels.map((digitPairWithLabel) => (
        <Grid item key={digitPairWithLabel.label}>
          <Units
            value={digitPairWithLabel.digit}
            label={digitPairWithLabel.label}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export const DigitsPairsWithLabels = memo(InternalDigitsPairsWithLabels);
