import { PropsWithChildren, createContext, useContext } from 'react';

const globalState = {
  featuredStreamsReloadCount: 0,
  increaseFeaturedStreamsReloadCount() {
    this.featuredStreamsReloadCount += 1;
  },
  resetFeaturedStreamsReloadCount() {
    this.featuredStreamsReloadCount = 0;
  },
};

const globalStateContext = createContext(globalState);

const { Provider } = globalStateContext;

export function GlobalStateProvider(props: PropsWithChildren) {
  const { children } = props;
  const globalState = useContext(globalStateContext);
  return (
    <Provider value={globalState}>
      {children}
    </Provider>
  );
}

export const useGlobalStateContext = () => {
  const context = useContext(globalStateContext);
  return context;
};
