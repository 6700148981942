// TODO: ideally this should be removed and done on server
// based on disconnect event
import { useEffect } from 'react';

export function useBeforeBrowserClose(
  onBrowserClose: () => void,
) {
  useEffect(() => {
    const onBeforeUnload = () => {
      onBrowserClose();
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [onBrowserClose]);
}
