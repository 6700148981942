import { useEffect } from 'react';
import {
  redirect, useNavigate, useParams,
} from 'react-router-dom';

export function RedirectStream() {
  const params = useParams();
  const multiplayerId = Number(params.multiplayerId);
  const scheduledStreamId = Number(params.scheduledStreamId);
  const isFeaturedView = Boolean(params.isFeaturedView);

  const featureViewParam = isFeaturedView ? `&isFeaturedView=${isFeaturedView}` : '';
  const navigate = useNavigate();

  useEffect(() => {
    if (multiplayerId) {
      navigate(`/stream?multiplayerId=${multiplayerId}${featureViewParam}`, { replace: true });
    }
    if (scheduledStreamId) {
      navigate(`/stream?scheduledStreamId=${scheduledStreamId}${featureViewParam}`, { replace: true });
    }
  }, [featureViewParam, multiplayerId, navigate, scheduledStreamId]);
  redirect('home.dorian.live');
  return null;
}
