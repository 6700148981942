import { AxiosError } from 'axios';
import {
  GetMultiplayerStatusResponseData,
} from '../../dorian-shared/types/multiplayerServer/GetMultiplayerStatusResponseData';
import { getAuthKeyPairFromLocalStorage } from '../../providers/AuthProvider';
import { multiplayerServer } from '../../services/multiplayerServer';

export const noStreamStoryId = -1;

export async function fetchMultiplayerStatus(multiplayerId: number) {
  const authKeyPair = getAuthKeyPairFromLocalStorage();
  try {
    const statusResponse = await multiplayerServer.post('/getMultiplayerStatusWeb', {
      multiplayerId,
      ...authKeyPair,
    });
    return statusResponse.data as GetMultiplayerStatusResponseData;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      const message = error.response?.data.message;
      if (
        message === 'Multiplayer room does not exist'
          || message === 'Stream does not exist'
          || message === 'Multiplayer room episode uuid does not exist'
      ) {
        // TODO: move this to backend
        return {
          episodeId: '',
          storyId: noStreamStoryId,
        } as GetMultiplayerStatusResponseData;
      }
    }
    throw error;
  }
}
