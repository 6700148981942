import { useMemo } from 'react';
import { CharacterVisualProperties } from '../../../../dorian-shared/types/character/CharacterVisualProperties';
import { CharactersResponseData } from '../../../../dorian-shared/types/multiplayerServer/CharactersResponseData';
import {
  EpisodeContentResponseData,
  Step,
} from '../../../../dorian-shared/types/multiplayerServer/EpisodeContentResponseData';
import { getStepDetails } from '../hooks/getStepDetails';

export const getIdPropertiesValidToPreload = (properties: object) => Object.keys(properties ?? {}).length > 1;

// TODO: make sure properties are not empty and they have type to preload
function collectNextCharactersProperties(
  episodeContent: EpisodeContentResponseData,
  charactersByAlias: CharactersResponseData,
  step: Step,
  branchName: string,
) {
  const nextBranchName = step.goto?.branch;
  if (nextBranchName) {
    const { character } = getStepDetails(episodeContent, charactersByAlias, nextBranchName, 0);
    if (character && getIdPropertiesValidToPreload(character.properties)) {
      return [character.properties];
    }
  }

  const answers = step?.answers;
  if (answers) {
    return answers.map((answer) => {
      const nextBranchName = answer.goto.branch;
      const { character } = getStepDetails(episodeContent, charactersByAlias, nextBranchName, 0);
      if (character && getIdPropertiesValidToPreload(character.properties)) {
        return character.properties;
      }
      return null;
    }).filter(Boolean) as CharacterVisualProperties[]; // TODO: ts doesn't pick up filter boolean
  }

  const { character } = getStepDetails(episodeContent, charactersByAlias, branchName, step.index + 1);
  if (character && getIdPropertiesValidToPreload(character.properties)) {
    return [character.properties];
  }

  return [];
}

export const useCharactersPropertiesToPreload = (
  episodeContent: EpisodeContentResponseData | undefined,
  charactersByAlias: CharactersResponseData | undefined,
  step: Step | undefined,
  branchName: string,
) => useMemo(
  () => (
    episodeContent && charactersByAlias && step
      ? collectNextCharactersProperties(episodeContent, charactersByAlias, step, branchName)
      : []
  ),
  [episodeContent, charactersByAlias, step, branchName],
);
