import { Typography, TypographyProps } from '@mui/material';
import clsx from 'clsx';
import { useMemo } from 'react';
import cs from './Text.module.css';

export interface TextProps extends TypographyProps {
    maxLineNumber?: number
}
export function Text(props: TextProps) {
  const {
    maxLineNumber, className, style, ...otherProps
  } = props;
  const hasMaxLinesEnabled = Boolean(maxLineNumber);
  const styleWithMaxLine = useMemo(() => (hasMaxLinesEnabled ? {
    WebkitLineClamp: maxLineNumber,
    ...style,
  } : style), [hasMaxLinesEnabled, maxLineNumber, style]);
  return (
    <Typography
      className={clsx(className, {
        [cs.rootWithMaxLineStyles]: hasMaxLinesEnabled,
      })}
      style={styleWithMaxLine}
      {...otherProps}
    />
  );
}
