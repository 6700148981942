import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import {
  ScheduledEventErrorMessage,
} from '../../dorian-shared/types/apiServer/getScheduledEventWeb/ScheduledEventErrorMessage';
import { getScheduledStreamWeb } from '../../services/apiServer';

export async function scheduledStreamPageLoader(args: LoaderFunctionArgs) {
  const { params } = args;
  const scheduledStreamId = Number(params.scheduledStreamId);
  if (!scheduledStreamId) {
    throw new Error('Stream id is not provided');
  }
  try {
    const scheduledStream = await getScheduledStreamWeb(scheduledStreamId);
    const { multiplayerId } = scheduledStream;

    if (multiplayerId) {
      return redirect(`/streams/${multiplayerId}`);
    }
    return scheduledStream;
  } catch (error) {
    if (error instanceof Error && error.message === ScheduledEventErrorMessage.NoEvent) {
      return redirect('/streams/1');
    }
    throw error;
  }
}
