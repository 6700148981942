import { useCallback, useEffect, useRef } from 'react';
import { NewHostSocketMessage } from '../../../../dorian-shared/types/stream-socket/NewHostSocketMessage';
import { useAuthKeyPair } from '../../../../providers/AuthProvider';
import { useMultiplayerContext } from '../../../../providers/MultiPlayerProvider';
import { MultiplayerEvent } from '../../../../services/multiplayerService/multiplayerService';
import { useBeforeBrowserClose } from './useBeforeBrowserClose';

const HEARTBEAT_INTERVAL = 60 * 1000;

export function useStreamMinutesCounter(
  multiplayerId: number,
) {
  const authKeyPair = useAuthKeyPair();
  const streamMinutesCounterRef = useRef(0);
  const multiplayerService = useMultiplayerContext();

  const sendStopHeartbeatEvent = useCallback(() => {
    multiplayerService.heartbeatStopWeb(multiplayerId, authKeyPair, streamMinutesCounterRef.current);
  }, [authKeyPair, multiplayerId, multiplayerService]);

  useBeforeBrowserClose(sendStopHeartbeatEvent);

  useEffect(() => {
    const intervalId = setInterval(() => {
      streamMinutesCounterRef.current += 1;
      multiplayerService.heartbeatStartWeb(multiplayerId, authKeyPair, streamMinutesCounterRef.current);
    }, HEARTBEAT_INTERVAL);

    const resetCounter = () => {
      clearInterval(intervalId);
      streamMinutesCounterRef.current = 0;
    };

    const stopStreamMinuteCounter = () => {
      sendStopHeartbeatEvent();
      resetCounter();
    };

    const handleNewHost = (data: NewHostSocketMessage) => {
      const {
        playerId: newHostPlayerId,
      } = data;

      if (!newHostPlayerId) {
        stopStreamMinuteCounter();
      }
    };

    multiplayerService.addListener(MultiplayerEvent.ShutDown, stopStreamMinuteCounter);
    multiplayerService.addListener(MultiplayerEvent.NewHost, handleNewHost);

    return () => {
      resetCounter();
      multiplayerService.removeListener(MultiplayerEvent.ShutDown, stopStreamMinuteCounter);
      multiplayerService.removeListener(MultiplayerEvent.NewHost, handleNewHost);
    };
  }, [authKeyPair, multiplayerId, multiplayerService, sendStopHeartbeatEvent]);
}
