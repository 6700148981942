import clsx from 'clsx';
import { AudienceIcon } from '../../../../icons/AudienceIcon';
import { isMobile } from '../../../../PageLayout/isMobile';
import cs from './StreamStatus.module.css';

export interface StreamStatusProps {
    viewsCount: number
    hostName: string
}
export function StreamStatus(props: StreamStatusProps) {
  const { viewsCount, hostName } = props;
  return (
    <div
      className={clsx(cs.container, {
        [cs.mobile]: isMobile,
      })}
    >
      <div>
        My role: Audience
      </div>
      <div>
        Host:
        {' '}
        {hostName}
      </div>
      <div className={cs.participantsCountContainer}>
        <AudienceIcon className={cs.icon} />
        <span>{viewsCount}</span>
      </div>
    </div>
  );
}
