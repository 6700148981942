import { DaysHoursMinutes } from './DaysHoursMinutes/DaysHoursMinutes';
import { HoursMinutesSeconds } from './HoursMinutesSeconds/HoursMinutesSeconds';

const NINETY_NINE_HOURS_IN_SECONDS = 356400;

interface CountdownProps {
    secondsLeft: number
}

export function Countdown(props: CountdownProps) {
  const { secondsLeft } = props;

  const isMoreThan99Hours = secondsLeft > NINETY_NINE_HOURS_IN_SECONDS;

  return isMoreThan99Hours
    ? <DaysHoursMinutes seconds={secondsLeft} />
    : <HoursMinutesSeconds seconds={secondsLeft} />;
}
