import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthKeyPair } from '../../../../providers/AuthProvider';
import { useStreamContext } from '../../../../providers/StreamProvider';
import { StreamEvent } from '../../../../services/streamService/streamService';
import { appId, appToken } from '../../utils/apiUtils';

export function useVideosByPlayerId(isStreamFinished: boolean) {
  const [videosByPlayerId, setVideosByPlayerId] = useState({});
  const params = useParams();
  const multiplayerId = Number(params.multiplayerId);
  const authKeyPair = useAuthKeyPair();
  const streamService = useStreamContext();

  useEffect(() => {
    if (isStreamFinished) {
      return undefined;
    }
    const usersChangedVideoCallback = streamService.addListener(StreamEvent.UsersChangedVideo, () => {
      setVideosByPlayerId(streamService.getRemoteUsers('video'));
    });
    streamService.join(multiplayerId, appId, appToken, authKeyPair.playerId).then(() => {
      streamService.setClientRole('audience');
      streamService.subscribeEvents();
    });
    return () => {
      streamService.unsubscribeAll();
      if (usersChangedVideoCallback) {
        streamService.removeListener(StreamEvent.UsersChangedVideo, usersChangedVideoCallback);
      }
      streamService.leave();
    };
  }, [authKeyPair.playerId, isStreamFinished, multiplayerId, streamService]);

  return { videosByPlayerId };
}
