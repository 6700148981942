import { PropsWithChildren, createContext, useContext } from 'react';
import { streamService } from '../services/streamService/streamService';

const streamContext = createContext(streamService);

const { Provider } = streamContext;

export function StreamProvider(props: PropsWithChildren) {
  const { children } = props;
  const streamService = useContext(streamContext);
  return (
    <Provider value={streamService}>
      {children}
    </Provider>
  );
}

export const useStreamContext = () => {
  const context = useContext(streamContext);
  if (context === undefined) {
    throw Error('Use within a StreamProvider');
  }
  return context;
};
