import { Box, Grid, Typography } from '@mui/material';
import pluralize from 'pluralize';
import { PropsWithChildren, memo } from 'react';
import { toTwoDigit } from '../../../../../../../dorian-shared/components/Countdown/toTwoDigits';

const Digit = memo(function Digit(props: PropsWithChildren) {
  const { children } = props;
  return (
    <Box bgcolor="primary.main" borderRadius={2.5} p={1.5} px={2}>
      <Typography variant="h1">
        {children}
      </Typography>
    </Box>
  );
});

interface UnitsProps {
    label: string
    value: number
}

export const Units = memo(function Units(props: UnitsProps) {
  const { label, value } = props;
  const pluralizedLabel = pluralize(label, value);
  const twoDigitValue = toTwoDigit(value);
  return (
    <>
      <Grid
        container
        spacing={2}
        color="primary.dark"
        justifyContent="center"
        mb={2}
      >
        <Grid item>
          <Digit>
            {twoDigitValue[0]}
          </Digit>
        </Grid>
        <Grid item>
          <Digit>
            {twoDigitValue[1]}
          </Digit>
        </Grid>
      </Grid>
      <Typography
        variant="h1"
        color="primary.light"
        textAlign="center"
        textTransform="uppercase"
      >
        {pluralizedLabel}
      </Typography>
    </>
  );
});
