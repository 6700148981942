import { getScheduledStreamWeb } from '../services/apiServer';
import { logger } from '../services/loggerService/loggerService';

export async function fetchScheduledStreamData(scheduledStreamId: number) {
  if (scheduledStreamId) {
    try {
      const scheduledStreamData = await getScheduledStreamWeb(scheduledStreamId);
      return scheduledStreamData;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }
  return undefined;
}
