import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStreamPageStyles = makeStyles((theme: Theme) => ({
  body: {
    backgroundColor: '#f2eeed',
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'transparent',
    },
  },
  getDorianApp: {
    display: 'block',
    position: 'fixed',
    top: '5%',
    right: '5%',
    'z-index': -1,
    [theme.breakpoints.down('xl')]: {
      width: '400px',
    },
    [theme.breakpoints.down('lg')]: {
      width: '300px',
      right: '10px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  streamInfo: {
    display: 'block',
    position: 'fixed',
    top: '15%',
    left: '5%',
    'max-width': '480px',
    'z-index': -1,
    [theme.breakpoints.down('xl')]: {
      width: '400px',
      left: '30px',
    },
    [theme.breakpoints.down('lg')]: {
      width: '300px',
      left: '0px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  streamInfoSmall: {
    'margin-left': '8px',
    'margin-right': '8px',
    'text-align': 'center',
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  streamContainerWithPaddings: {
    position: 'relative',
    margin: '20px auto',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  coverImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    overflow: 'hidden',
    border: '5px solid white',
    boxShadow: '0 0 20px black',
    borderRadius: '16px',
    backgroundColor: 'var(--dark-blue)',
    [theme.breakpoints.down('sm')]: {
      boxShadow: '0 0 8px black',
    },
  },
  streamContainer: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'content-box',
    border: '5px solid white',
    boxShadow: '0 0 20px black',
    borderRadius: '16px',
    zIndex: 0,
    backgroundColor: 'var(--dark-blue)',
    [theme.breakpoints.down('sm')]: {
      boxShadow: '0 0 8px black',
    },
  },
}));
