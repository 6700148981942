import launch from '../images/backgrounds/launch.jpg';
import { getFullURL } from '../services/s3EngineObjectsCache';

export const COVER_URL_FALLBACK = launch;

export function getCoverUrl(url: string | undefined): string {
  if (url) {
    return getFullURL(url);
  }
  return COVER_URL_FALLBACK;
}
