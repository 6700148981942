import {
  CharactersResponseData,
} from '../../../../dorian-shared/types/multiplayerServer/CharactersResponseData';
import {
  EpisodeContentResponseData,
} from '../../../../dorian-shared/types/multiplayerServer/EpisodeContentResponseData';

export function getStepDetails(
  episodeContent: EpisodeContentResponseData | undefined,
  charactersByAlias: CharactersResponseData | undefined,
  branchName: string,
  stepIndex: number,
) {
  const branch = episodeContent?.branches[branchName];
  const step = branch?.steps[stepIndex];
  const scene = episodeContent?.scenes[branch?.scene ?? ''];
  const character = charactersByAlias?.[step?.character?.alias ?? ''];
  return {
    scene,
    step,
    character,
  };
}
