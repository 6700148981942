import {
  PropsWithChildren, createContext, useContext, useState,
} from 'react';
import { Popup, PopupProps } from './Popup/Popup';

interface PopupContext {
    showPopup: (popupConfig: PopupProps) => void
    hidePopup: () => void,
}

const popupContext = createContext<PopupContext>({
  showPopup: () => {
    throw new Error('Popup context used before initialization');
  },
  hidePopup: () => {
    throw new Error('Popup context used before initialization');
  },
});
const PopupContextProvider = popupContext.Provider;

export function PopupProvider(props: PropsWithChildren) {
  const { children } = props;

  const [popupProps, setPopupProps] = useState<PopupProps | null>(null);
  return (
    <PopupContextProvider value={{
      showPopup: (popupProps) => setPopupProps(popupProps),
      hidePopup: () => setPopupProps(null),
    }}
    >
      {children}
      {popupProps && <Popup {...popupProps} />}
    </PopupContextProvider>
  );
}

export const usePopup = () => useContext(popupContext);
