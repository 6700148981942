import React, { useEffect, useRef, useState } from 'react';
import { getSizeConfig } from '../../../dorian-shared/getSizeConfig';
import { MuteButton } from '../../components/MuteButton/MuteButton';
import cs from './FeaturedStreamVideo.module.css';

interface FeaturedStreamVideoParams {
  videoUrl: string;
  videoNum: number;
  onVideoEnded: () => void;
}

export function FeaturedStreamVideo(params: FeaturedStreamVideoParams) {
  const { videoUrl, videoNum, onVideoEnded } = params;
  const [isMuted, setIsMuted] = useState(true);

  const { width, height } = getSizeConfig();

  const videoRef = useRef<HTMLVideoElement>(null);

  const onMuteChange = (isVideoMuted: boolean) => {
    setIsMuted(isVideoMuted);

    const video = videoRef.current;
    if (!video) {
      return;
    }

    video.muted = isVideoMuted;
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.src = videoUrl;
      video.addEventListener('ended', onVideoEnded);
      video.currentTime = 0;
      video.play();

      return () => {
        video.removeEventListener('ended', onVideoEnded);
      };
    }
    return undefined;
  }, [videoUrl, videoNum, onVideoEnded]);

  return (
    <div className={cs.container}>
      <div className={cs.muteContainer}>
        <MuteButton isMuted={isMuted} onMuteChange={onMuteChange} />
      </div>
      <video
        autoPlay
        muted
        ref={videoRef}
        width={width}
        height={height}
        key={videoUrl}
      >
        <source type="video/mp4" />
      </video>
    </div>
  );
}
