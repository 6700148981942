import { parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import {
  ScheduledEventResponseData,
  ScheduledEventStatus,
} from '../dorian-shared/types/apiServer/getScheduledEventWeb/ScheduledEventResponseData';
import { getScheduledStreamWeb } from '../services/apiServer';

export function useFetchStreamStatusByInterval(
  scheduledStreamId: number | null,
  intervalInMs: number = 10 * 1000,
) {
  const [scheduledStreamData, setScheduledStreamData] = useState<ScheduledEventResponseData>();

  const getData = useCallback(() => {
    const beforeDate = parseISO(scheduledStreamData?.timeBefore ?? '');
    const isTimeToGoLive = beforeDate <= new Date();

    if (isTimeToGoLive) {
      getScheduledStreamWeb(Number(scheduledStreamId)).then((scheduledStream) => {
        setScheduledStreamData(scheduledStream);
      });
    }
  }, [scheduledStreamData?.timeBefore, scheduledStreamId]);

  useEffect(() => {
    if (!scheduledStreamId) {
      return;
    }
    getScheduledStreamWeb(Number(scheduledStreamId)).then((scheduledStream) => {
      setScheduledStreamData(scheduledStream);
    });
  }, [scheduledStreamId]);

  useEffect(() => {
    if (!scheduledStreamId) {
      return () => undefined;
    }
    const intervalId = scheduledStreamId ? setInterval(() => getData(), intervalInMs) : undefined;
    if (scheduledStreamData?.status === ScheduledEventStatus.Finished) {
      clearInterval(intervalId);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [getData, intervalInMs, scheduledStreamData?.status, scheduledStreamId]);

  return {
    scheduledStreamData,
  };
}
