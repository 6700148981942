import { QRCode } from '../../PageLayout/QRCode/QRCode';
import characters from './characters.png';
import chatIcon from './chat.png';
import emojiIcon from './emoji.png';
import cs from './GetDorianAppSection.module.css';
import supportIcon from './support.png';

export function GetDorianAppSection() {
  return (
    <div className={cs.getTheAppSection}>
      <img className={cs.charactersContainer} src={characters} alt="characters" />
      <div className={cs.container}>
        <div className={cs.getTheAppSectionHeader}> Get the Dorian app! </div>
        <ul className={cs.getTheAppProList}>
          <li className={cs.getTheAppProListItem}>
            <img className={cs.getTheAppProListItemIcon} src={chatIcon} alt="" />
            <div className={cs.getTheAppProListItemText}> Chat! </div>
          </li>
          <li className={cs.getTheAppProListItem}>
            <img className={cs.getTheAppProListItemIcon} src={emojiIcon} alt="" />
            <div className={cs.getTheAppProListItemText}> Send Emojis! </div>
          </li>
          <li className={cs.getTheAppProListItem}>
            <img className={cs.getTheAppProListItemIcon} src={supportIcon} alt="" />
            <div className={cs.getTheAppProListItemText}> Support Creators! </div>
          </li>
          <li className={cs.getTheAppProListItem}>
            <div className={cs.getTheAppProListItemIconEmpty} />
            <div className={cs.getTheAppProListItemText}> And more! </div>
          </li>
        </ul>
        <div className={cs.getTheAppQrSection}>
          <div className={cs.getTheAppQrSectionText}><p>Scan the code to download </p></div>
          <QRCode />
        </div>
      </div>
    </div>
  );
}
