export enum RuntimeEnv {
  Prod,
  Stg,
  Dev,
  Local,
  Porpoise
}

const prodHostnames = [
  'dorianplatform.com',
  'dorianlive.tv',
  'dorian.watch',
  'dorian.live',
];

export const getRuntimeEnv = () => {
  const { hostname } = window.location;
  if (prodHostnames.some((prodHostname) => hostname.endsWith(prodHostname))) {
    return RuntimeEnv.Prod;
  }
  if (hostname.endsWith('stg.4dorian.net')) {
    return RuntimeEnv.Stg;
  }
  if (hostname.endsWith('dev.4dorian.net') || hostname.endsWith('rev.4dorian.net')) {
    return RuntimeEnv.Dev;
  }
  if (hostname.endsWith('.test.4dorian.net')) {
    return RuntimeEnv.Porpoise;
  }
  if (hostname.endsWith('localhost')) {
    return RuntimeEnv.Local;
  }

  // fallback
  return RuntimeEnv.Dev;
};

export const isProd = () => getRuntimeEnv() === RuntimeEnv.Prod;
export const isDev = () => [RuntimeEnv.Dev, RuntimeEnv.Porpoise].includes(getRuntimeEnv());
export const isLocal = () => getRuntimeEnv() === RuntimeEnv.Local;
