import { RuntimeEnv, getRuntimeEnv } from '../../../getRuntimeEnv';

const runtimeEnv = getRuntimeEnv();
const envLabel = runtimeEnv === RuntimeEnv.Prod ? 'prod' : 'staging';

const appIdByEnvLabel = {
  local: 'bdac5967c21848ab89d0d3eda11c5ba9',
  unstable1: 'bdac5967c21848ab89d0d3eda11c5ba9',
  unstable2: 'bdac5967c21848ab89d0d3eda11c5ba9',
  staging: 'bdac5967c21848ab89d0d3eda11c5ba9',
  prod: 'b53adb7924644d4e9e8b4b8ab5ef0b2a',
};

export const appId = appIdByEnvLabel[envLabel];

const appTokenByEnvLabel = {
  local: null,
  unstable1: null,
  unstable2: null,
  staging: null,
  prod: null,
};

export const appToken = appTokenByEnvLabel[envLabel];
