import { useEffect, useRef, useState } from 'react';

export function useCountdown(initialSeconds: number, onCountdownEnd?: () => void) {
  const [secondsLeft, setSecondsLeft] = useState(initialSeconds);
  const intervalIdRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (secondsLeft <= 0) {
      clearInterval(intervalIdRef.current);
      onCountdownEnd?.();
    }
    // onCountdownEnd is easy to pass as arrow function which will cause infinite cycle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsLeft]);
  useEffect(() => {
    intervalIdRef.current = setInterval(() => {
      setSecondsLeft((secondsLeft) => {
        const newSecondsLeft = secondsLeft - 1;
        if (!newSecondsLeft) {
          clearInterval(intervalIdRef.current);
          return newSecondsLeft;
        }
        return newSecondsLeft;
      });
    }, 1000);

    return () => clearInterval(intervalIdRef.current);
    // onCountdownEnd is easy to pass as arrow function which will cause infinite cycle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return secondsLeft;
}
