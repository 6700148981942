import { hoursToSeconds, secondsToHours, secondsToMinutes } from 'date-fns';
import { useMemo } from 'react';
import { DigitsPairsWithLabels } from '../components/DigitsPairsWithLabels/DigitsPairsWithLabels';

const HOURS_IN_DAY = 24;

function hoursToDays(hours: number) {
  return Math.floor(hours / HOURS_IN_DAY);
}

function secondsToDays(seconds: number) {
  const wholeHours = secondsToHours(seconds);
  return hoursToDays(wholeHours);
}

function daysToHours(days: number) {
  return days * HOURS_IN_DAY;
}

function daysToSeconds(days: number) {
  const hours = daysToHours(days);
  return hoursToSeconds(hours);
}

export function getDaysHoursMinutes(secondsLeft: number) {
  const wholeDays = secondsToDays(secondsLeft);

  const hoursInSecondsLeft = secondsLeft - daysToSeconds(wholeDays);
  const wholeHours = secondsToHours(hoursInSecondsLeft);

  const minutesInSecondsLeft = hoursInSecondsLeft - hoursToSeconds(wholeHours);

  const wholeMinutes = secondsToMinutes(minutesInSecondsLeft);
  return [
    wholeDays,
    wholeHours,
    wholeMinutes,
  ] as const;
}

interface DaysHoursMinutesProps {
    seconds: number
}

export function DaysHoursMinutes(props: DaysHoursMinutesProps) {
  const {
    seconds,
  } = props;
  const [
    days,
    hours,
    minutes,
  ] = getDaysHoursMinutes(seconds);

  const digitPairsWithLabels = useMemo(() => [
    {
      digit: days,
      label: 'day',
    },
    {
      digit: hours,
      label: 'hour',
    },
    {
      digit: minutes,
      label: 'minute',
    },
  ], [days, hours, minutes]);

  return (
    <DigitsPairsWithLabels
      digitPairsWithLabels={digitPairsWithLabels}
    />
  );
}
