import { useCallback, useState } from 'react';

export function useToggle(initialValue = false) {
  const [isOn, setIsOn] = useState(initialValue);
  const switchOn = useCallback(() => setIsOn(true), []);
  const switchOff = useCallback(() => setIsOn(false), []);
  const toggle = useCallback(() => setIsOn((prevValue) => !prevValue), []);
  return [
    isOn,
    switchOn,
    switchOff,
    toggle,
  ] as const;
}
