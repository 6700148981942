import { useEffect, useState } from 'react';
import { EpisodeContentResponseData } from '../../../dorian-shared/types/multiplayerServer/EpisodeContentResponseData';
import { s3EngineObjectsCache } from '../../../services/s3EngineObjectsCache';

export function useEpisode(episodeUuid: string | undefined) {
  const [episodeContent, setEpisodeContent] = useState<EpisodeContentResponseData | undefined>();

  useEffect(() => {
    if (episodeUuid) {
      (async () => {
        try {
          const response = await s3EngineObjectsCache.get<EpisodeContentResponseData>(
            `/getEpisodeContent/${episodeUuid}?t=${Date.now()}`,
          );
          setEpisodeContent(response.data);
        } catch (e) {
          // TODO: handle error
        }
      })();
    }
  }, [episodeUuid]);

  return episodeContent;
}
