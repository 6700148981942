import React, {
  PropsWithChildren, createContext, useContext, useEffect, useState,
} from 'react';
import { v4 as uuid } from 'uuid';
import { multiplayerServer, serverMultiplayerUrl } from '../services/multiplayerServer';

function getStorageKey() {
  return `authKeyPairForURL:${serverMultiplayerUrl}`;
}

export const getAuthKeyPairFromLocalStorage = () => {
  try {
    const authKeyPairStringified = localStorage.getItem(getStorageKey());
    if (!authKeyPairStringified) {
      return null;
    }
    return JSON.parse(authKeyPairStringified);
  } catch {
    return null;
  }
};

export interface AuthKeyPair {
    playerId: string
    serverId: string
}

function setAuthKeyPairToLocalStorage(authKeyPair: AuthKeyPair) {
  localStorage.setItem(getStorageKey(), JSON.stringify(authKeyPair));
}

const authContext = createContext<AuthKeyPair | undefined>(undefined);
const AuthContextProvider = authContext.Provider;

export const useAuthKeyPair = () => {
  const authKeyPair = useContext(authContext);
  if (!authKeyPair) {
    // TODO: handle auth error properly
    throw new Error('Issue when auth');
  }
  return authKeyPair;
};

export function AuthProvider(props: PropsWithChildren) {
  const { children } = props;
  const [authKeyPair, setAuthKeyPair] = useState<AuthKeyPair>();
  useEffect(() => {
    (async () => {
      try {
        const authKeyPairFromLocalStorage = getAuthKeyPairFromLocalStorage();
        if (authKeyPairFromLocalStorage) {
          setAuthKeyPair(authKeyPairFromLocalStorage);
          return;
        }

        const playerId = uuid();
        const serverId = uuid();
        const authKeyPair = {
          playerId,
          serverId,
        };
        await multiplayerServer.put('/authenticateWeb', authKeyPair);
        // TODO: make localStorage source of truth extract to separate hook
        setAuthKeyPairToLocalStorage(authKeyPair);
        setAuthKeyPair(authKeyPair);
      } catch {
        // TODO: handle error with toasts
        // eslint-disable-next-line no-alert
        alert('There was an issue, please contact support or try again later.');
      }
    })();
  }, []);

  return authKeyPair ? (
    <AuthContextProvider value={authKeyPair}>
      {children}
    </AuthContextProvider>
  ) : null;
  // TODO: add loading here instead of null for auth state
}
