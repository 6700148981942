import { Component, PropsWithChildren } from 'react';
import cs from './ErrorBoundary.module.css';

export class ErrorBoundary extends Component<PropsWithChildren, {hasError: boolean}> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(/* error, errorInfo */) {
    // You can also log the error to an error reporting service
    // TODO: sentry
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className={cs.container}>
          Error occurred, please contact support or reload the page
        </div>
      );
    }

    return children;
  }
}
