import { isMobile } from '../PageLayout/isMobile';

const phoneScreenAspectRatio = 2.16;
const heightPadding = 50;

function getPhoneWidthByHeight(height: number) {
  return height / phoneScreenAspectRatio;
}

function getPhoneHeightByWidth(width: number) {
  return width * phoneScreenAspectRatio;
}

export function getSizeConfig() {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight - heightPadding;

  const maxWidth = getPhoneWidthByHeight(windowHeight);
  const maxHeight = getPhoneHeightByWidth(windowWidth);

  const width = windowWidth > maxWidth ? maxWidth : windowWidth;
  const height = windowHeight > maxHeight ? maxHeight : windowHeight;

  const gameplayPaddings = 20 + 20 + 5 + 5; // margin + border
  const gameplayWidth = width - gameplayPaddings;
  const gameplayHeight = height - (gameplayPaddings * phoneScreenAspectRatio);

  return isMobile ? {
    height: window.innerHeight,
    width: window.innerWidth,
    gameplayHeight,
    gameplayWidth,
  } : {
    height,
    width,
    gameplayHeight,
    gameplayWidth,
  };
}

export const isAdditionalInfoShow = window.innerWidth > 1200;

// TODO: Move to utils or rename this file
export const setBodyColor = (color: string) => {
  document.body.style.backgroundColor = color;
};
