import { UPDATE_TYPE } from '@dorian/gameplay-renderer';
import { STORY_STATE } from '@dorian/gameplay-renderer/dist/consts';
import { GameUIStyle } from '@dorian/gameplay-renderer/dist/models/GameUIStyle';
import { VotesData } from '@dorian/gameplay-renderer/dist/models/VotesData';
import React, { useEffect } from 'react';
import {
  getAssetsBaseUrl,
  getEnvPrefix,
} from '../../../../../dorian-shared/hooks/useAvatarResolver/getManifestUrlByRuntimeEnv';
import { CharacterVisualProperties } from '../../../../../dorian-shared/types/character/CharacterVisualProperties';
import { Scene, Step } from '../../../../../dorian-shared/types/multiplayerServer/EpisodeContentResponseData';
import { ChoiceMemoryVariablesValuesByName } from '../../../../../dorian-shared/types/stream-socket/Advance';
import { RuntimeEnv, getRuntimeEnv } from '../../../../../getRuntimeEnv';
import { getGameplayRendererVariables } from '../../../utils/getGameplayRendererVariables';
import cs from './GameplayRendererWrapper.module.css';
import { useGameplayRenderer } from './useGameplayRenderer';
import { UseStepRenderCharacter, useStepRender } from './useStepRender/useStepRender';

const runtimeEnv = getRuntimeEnv();
const envLabel = runtimeEnv === RuntimeEnv.Prod ? 'prod' : 'staging';

const assetsBaseUrl = getAssetsBaseUrl(envLabel);
const envPrefix = getEnvPrefix(envLabel);

function getBackground(alias: string) {
  return `${assetsBaseUrl}/${envPrefix}/backgrounds/${alias}.jpg`;
}

interface Character extends UseStepRenderCharacter {
  properties: CharacterVisualProperties
}

export interface GameplayRendererWrapperProps {
  width: number
  height: number

  step: Step
  scene: Scene
  charactersPropertiesToPreload: CharacterVisualProperties[]
  playerName: string
  gameUIStyle: GameUIStyle
  character?: Character
  votingResults?: VotesData
  choiceMemoryVariablesValuesByName?: ChoiceMemoryVariablesValuesByName
}

export function GameplayRendererWrapper(props: GameplayRendererWrapperProps) {
  const {
    width,
    height,

    step,
    scene,
    charactersPropertiesToPreload,
    playerName,
    gameUIStyle,
    character,
    votingResults,
    choiceMemoryVariablesValuesByName,
  } = props;

  const { containerRef, gameplayRenderer } = useGameplayRenderer(
    width,
    height,
    gameUIStyle,
  );
  useEffect(() => {
    (async () => {
      if (!gameplayRenderer) {
        return;
      }

      const backgroundImage = getBackground(scene.alias);
      await gameplayRenderer.setBackgroundImage(backgroundImage);
    })();
  }, [scene, gameplayRenderer]);

  useStepRender(gameplayRenderer, step, step.character, character, playerName, choiceMemoryVariablesValuesByName);

  useEffect(() => {
    if (!gameplayRenderer) {
      return;
    }

    charactersPropertiesToPreload.forEach((properties) => {
      const hasMoreThanTypeFieldToPreload = Object.keys(properties).length > 1;

      if (properties && hasMoreThanTypeFieldToPreload) {
        // we have to feed here copy of the object, because avatar system, under the hood of GR is mutating
        // our object while not finding all needed props
        gameplayRenderer.preloadAvatarResources({ ...properties });
      }
    });
  }, [gameplayRenderer, charactersPropertiesToPreload]);

  useEffect(() => {
    (async () => {
      if (!gameplayRenderer || !votingResults) {
        return;
      }
      await gameplayRenderer.updateTextBubble({
        type: STORY_STATE.Choice,
        updateType: UPDATE_TYPE.VOTES_EXTRAS,
        votesData: votingResults,
      });
      await gameplayRenderer.updateTextBubble({
        type: STORY_STATE.Choice,
        updateType: UPDATE_TYPE.ANSWER_SELECT,
        answerNum: votingResults.winningAnswer,
      });
    })();
  }, [gameplayRenderer, votingResults]);

  const variables = getGameplayRendererVariables(width);
  return (
    <div
      ref={containerRef}
      className={cs.previewContainer}
      style={{
        ...variables,
        width,
        height,
      }}
    />
  );
}
