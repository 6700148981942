import { useEffect, useState } from 'react';
import { AvatarSystem } from './avatar-system';
import { EnvLabel, getAssetsBaseUrl, getEnvPrefix } from './getManifestUrlByRuntimeEnv';
import { loadAvatarSystemConfig } from './loadAvatarSystem';

export declare type AvatarCompositionItem = {
  name: string;
  src: string;
  blend: string;
  rect: number[];
};
export declare type AvatarComposition = AvatarCompositionItem[];
export interface AvatarResolver {
  getComposition: (avatarConfig: object) => AvatarComposition;
  getImageURL: (url: string) => string;
}
export function useAvatarResolver(envLabel: EnvLabel) {
  const [avatarResolver, setAvatarResolver] = useState<AvatarResolver>();
  useEffect(() => {
    (async () => {
      const envPrefix = getEnvPrefix(envLabel);
      const assetsUrl = getAssetsBaseUrl(envLabel);
      const avatarSystemConfig = await loadAvatarSystemConfig(assetsUrl, envPrefix);
      const avatarSystem = new AvatarSystem({
        ...avatarSystemConfig,
        // in client there are also local assets which are preferred, in engine we don't have it
        localAssetManifest: {},
        localAssetURL: '',
      });
      setAvatarResolver(avatarSystem);
    })();
  }, [envLabel]);
  return avatarResolver;
}
