import axios from 'axios';
import { ScheduledEventResponseData } from '../dorian-shared/types/apiServer/getScheduledEventWeb/ScheduledEventResponseData';
import { RuntimeEnv, getRuntimeEnv } from '../getRuntimeEnv';
import { getAuthKeyPairFromLocalStorage } from '../providers/AuthProvider';

const ROOT_DOMAIN = 'test.4dorian.net';
const DEFAULT_PORPOISE_NAMESPACE = 'dev';
const PORPOISE_NAMESPACE_MATCHER = new RegExp(`^[\\w]+\\.([\\w]+)\\.${ROOT_DOMAIN.replace(/\./g, '\\.')}$`);
const porpoisePath = (sub: string, namespace: string) => `https://${sub}.${namespace}.${ROOT_DOMAIN}`;

const getPorpoiseSubdomain = (sub: string, _hostname?: string): string => {
  const namespace = PORPOISE_NAMESPACE_MATCHER
    .exec(_hostname || window?.location?.hostname || '')?.[1] || DEFAULT_PORPOISE_NAMESPACE;
  return porpoisePath(sub, namespace);
};

const apiServerUrlByEnvLabel = {
  local: 'http://localhost', // TODO: which port
  unstable1: 'http://ec2-3-141-106-2.us-east-2.compute.amazonaws.com:3003',
  unstable2: 'http://ec2-18-217-33-72.us-east-2.compute.amazonaws.com:3003',
  staging: 'http://ec2-52-14-76-18.us-east-2.compute.amazonaws.com:3003',
  tactical: 'http://ec2-52-14-76-18.us-east-2.compute.amazonaws.com:3003',
  prod: 'https://prod.api.v2.beta.4dorian.net',
  porpoise: getPorpoiseSubdomain('api'),
} as Record<string, string>;

const runtimeEnv = getRuntimeEnv();
let envLabel = runtimeEnv === RuntimeEnv.Prod ? 'prod' : 'staging';

if (runtimeEnv === RuntimeEnv.Porpoise) {
  envLabel = 'porpoise';
}

export const apiServerUrl = apiServerUrlByEnvLabel[envLabel];

export const apiServer = axios.create({
  baseURL: `${apiServerUrl}/v2`,
});

export async function getScheduledStreamWeb(scheduledStreamId: number) {
  const authKeyPair = getAuthKeyPairFromLocalStorage();
  const response = await apiServer.post<ScheduledEventResponseData>('/getScheduledEventWeb', {
    ...authKeyPair,
    eventId: scheduledStreamId,
  });
  return response.data;
}
