import { IRemoteVideoTrack } from 'agora-rtc-sdk-ng';
import { forwardRef, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { BaseParticipant } from '../../../types/BaseParticipant';
import cs from './Participant.module.css';
import { useLargeVideoSize } from './useLargeVideoSize';

interface ParticipantProps {
  participant: BaseParticipant
  video?: Pick<IRemoteVideoTrack, 'play'| 'stop' | 'isPlaying'>
  isLargeVideo: boolean
}

const LargeVideoPortalContent = forwardRef<HTMLDivElement>((props, ref) => {
  const largeVideoPortalContainerEl = document.getElementById('LargeVideoPortalContainer');
  const largeVideoSize = useLargeVideoSize();
  return largeVideoPortalContainerEl && createPortal(
    <div
      ref={ref}
      style={{
        width: largeVideoSize,
        height: largeVideoSize,
      }}
    />,
    largeVideoPortalContainerEl,
  );
});
LargeVideoPortalContent.displayName = 'LargeVideoPortalContent';

export function Participant(props: ParticipantProps) {
  const { participant, video, isLargeVideo } = props;
  const defaultContainerRef = useRef(null);
  const largeContainerRef = useRef(null);

  useEffect(() => {
    const defaultContainerEl = defaultContainerRef.current;
    const largeContainer = largeContainerRef.current;
    if (video && defaultContainerEl) {
      if (isLargeVideo && largeContainer) {
        video.play(largeContainer);
      } else {
        video.play(defaultContainerEl);
      }
    }
  }, [video, isLargeVideo]);

  return (
    <>
      <div
        className={cs.container}
        ref={defaultContainerRef}
        style={{
          backgroundImage: `url(${participant.avatarUrl})`,
          borderRadius: video?.isPlaying && !isLargeVideo ? 0 : '50%',
        }}
      />
      {isLargeVideo && <LargeVideoPortalContent ref={largeContainerRef} />}
    </>
  );
}
