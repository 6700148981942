import { AxiosError } from 'axios';
import { useRouteError } from 'react-router-dom';
import { ErrorCode } from '../../dorian-shared/types/apiServer/ErrorCode';
import {
  ScheduledEventErrorMessage,
} from '../../dorian-shared/types/apiServer/getScheduledEventWeb/ScheduledEventErrorMessage';
import cs from './ErrorPage.module.css';

export function ErrorPage() {
  const error = useRouteError();
  if (error instanceof AxiosError && error.response) {
    if (error.response.data.message === ScheduledEventErrorMessage.NoEvent) {
      return (
        <div className={cs.container}>
          This event doesn't exist, please contact support if you see this error.
        </div>
      );
    }
    if (error.response.data.errorCode === ErrorCode.InvalidUser) {
      return (
        <div className={cs.container}>
          User is invalid, please clear local storage or contact support if you see this error.
        </div>
      );
    }
  }
  return (
    <div className={cs.container}>
      Something went wrong - please try again later
    </div>
  );
}
