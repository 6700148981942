// TODO: https://dorian.atlassian.net/browse/DOR-3143
// copied from source/www/js/loadAvatarSystem.js

export async function loadAvatarSystemConfig(assetsUrl, environment) {
  const now = Date.now();
  const baseUrl = assetsUrl;

  const manifestUrl = `${baseUrl}/${environment}/asset-manifest.json?t=${now}`;
  const configUrl = `${baseUrl}/${environment}/AvatarConfig.js?t=${now}`;

  const assetManifest = await fetch(manifestUrl).then((res) => res.json());
  const avatarConfigSrc = await fetch(configUrl).then((res) => res.blob());

  const script = document.createElement('script');

  await new Promise((resolve, reject) => {
    script.onload = resolve;
    script.onerror = reject;
    script.src = URL.createObjectURL(avatarConfigSrc);
    document.body.appendChild(script);
  });

  URL.revokeObjectURL(script.src);
  script.remove();

  return {
    config: window.AVATAR_CONFIG,
    assetManifest,
    localAssetManifest: window.ASSET_MANIFEST,
    localAssetURL: 'assets/img/',
  };
}
