import { Box } from '@mui/material';
import { isAdditionalInfoShow } from '../../dorian-shared/getSizeConfig';
import liveIcon from '../../icons/liveIcon.svg';
import cs from './StreamInfoSectionV2Small.module.css';

const avatarSize = 34;
interface StreamInfoSectionProps {
    eventTimeTitle?: string
    eventTitle: string
    hostName: string
    hostAvatarUrl: string
    viewsCount?: number
}

export function StreamInfoSectionV2Small(props: StreamInfoSectionProps) {
  const {
    eventTimeTitle,
    eventTitle,
    hostName,
    hostAvatarUrl,
    viewsCount,
  } = props;
  return (
    <div className={cs.StreamInfoSectionV2}>
      {hostName && (
        <div className={cs.hostBottom}>
          <Box
            component="img"
            src={hostAvatarUrl}
            alt="avatar"
            width={avatarSize}
            height={avatarSize}
            className={cs.avatarImage}
            borderRadius="100%"
          />
          <div
            className={cs.hostName}
          >
            {hostName}
          </div>
        </div>
      )}
      <div
        className={cs.eventTitle}
        style={isAdditionalInfoShow ? {} : { fontSize: '14px' }}
      >
        {eventTitle}
      </div>
      {eventTimeTitle
        ? (
          <div
            className={cs.eventTimeTitle}
            style={isAdditionalInfoShow ? {} : { marginBottom: '7px', fontSize: '16px' }}
          >
            {eventTimeTitle}
          </div>
        )
        : (
          <>

            <div
              className={cs.eventLive}
              style={isAdditionalInfoShow ? {} : { marginBottom: '7px', fontSize: '18px' }}
            >
              <img src={liveIcon} alt="live" className={cs.liveIndicator} />
              <div
                className={cs.eventLiveTitle}
              >
                Live Now
              </div>
            </div>
            {viewsCount && (
              <div
                className={cs.viewsCount}
              >
                {`${viewsCount} Playing`}
              </div>
            )}
          </>
        )}

    </div>
  );
}
