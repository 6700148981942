import { SvgIcon, SvgIconProps } from '@mui/material';

/* eslint-disable max-len */
export function HeartIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12 20.2204L10.7 19.0554C6.06 14.9324 3 12.2134 3 8.87644C2.99988 8.23075 3.12853 7.59153 3.37842 6.99616C3.62832 6.40079 3.99444 5.86124 4.45536 5.40906C4.91629 4.95689 5.46276 4.60118 6.06281 4.36275C6.66286 4.12431 7.30443 4.00794 7.95 4.02044C8.71706 4.02464 9.47453 4.19122 10.1726 4.50922C10.8706 4.82722 11.4934 5.28943 12 5.86544C12.5066 5.28943 13.1294 4.82722 13.8274 4.50922C14.5255 4.19122 15.2829 4.02464 16.05 4.02044C16.6956 4.00794 17.3371 4.12431 17.9372 4.36275C18.5372 4.60118 19.0837 4.95689 19.5446 5.40906C20.0056 5.86124 20.3717 6.40079 20.6216 6.99616C20.8715 7.59153 21.0001 8.23075 21 8.87644C21 12.2134 17.94 14.9324 13.3 19.0644L12 20.2204Z" fill="url(#paint0_linear_4637_38588)" />
      <path d="M14.0438 4.64551C11.2788 5.59351 10.0328 8.90151 9.94385 11.1455C11.2678 9.32551 14.0438 4.64551 14.0438 4.64551Z" fill="url(#paint1_linear_4637_38588)" />
      <path d="M7 7.02051C7.55228 7.02051 8 6.57279 8 6.02051C8 5.46822 7.55228 5.02051 7 5.02051C6.44772 5.02051 6 5.46822 6 6.02051C6 6.57279 6.44772 7.02051 7 7.02051Z" fill="white" />
      <path d="M5.09723 7.70542C3.49924 10.7504 7.63498 13.8162 8.24543 14.4865" stroke="white" strokeLinecap="round" />
      <defs>
        <linearGradient id="paint0_linear_4637_38588" x1="14.646" y1="10.9373" x2="6.71272" y2="4.31741" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F56969" />
          <stop offset="1" stopColor="#FF9898" />
        </linearGradient>
        <linearGradient id="paint1_linear_4637_38588" x1="10.198" y1="10.905" x2="9.56311" y2="4.68437" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F7A397" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
