import { SvgIcon, SvgIconProps } from '@mui/material';

/* eslint-disable max-len */
export function MessageIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M6.00684 14.025H13.8318V12.525H6.00684V14.025ZM6.00684 10.775H18.0068V9.275H6.00684V10.775ZM6.00684 7.525H18.0068V6.025H6.00684V7.525ZM2.00684 22V3.5C2.00684 3.11667 2.15684 2.77083 2.45684 2.4625C2.75684 2.15417 3.10684 2 3.50684 2H20.5068C20.8902 2 21.236 2.15417 21.5443 2.4625C21.8527 2.77083 22.0068 3.11667 22.0068 3.5V16.5C22.0068 16.8833 21.8527 17.2292 21.5443 17.5375C21.236 17.8458 20.8902 18 20.5068 18H6.00684L2.00684 22ZM3.50684 18.375L5.38184 16.5H20.5068V3.5H3.50684V18.375ZM3.50684 3.5V18.375V3.5Z" fill="white" />
    </SvgIcon>
  );
}
