// based on source/www/js/server/ServerConfig.js

const envPrefixByEnvLabel = {
  local: 'local',
  unstable1: 'staging',
  unstable2: 'staging',
  staging: 'staging',
  prod: 'prod',
};

export type EnvLabel = keyof typeof envPrefixByEnvLabel;

export function getEnvPrefix(envLabel: EnvLabel) {
  return envPrefixByEnvLabel[envLabel];
}

const assetsUrlByEnvLabel = {
  local: 'http://localhost',
  unstable1: 'https://dorian-client-assets.s3.us-east-2.amazonaws.com',
  unstable2: 'https://dorian-client-assets.s3.us-east-2.amazonaws.com',
  staging: 'https://dorian-client-assets.s3.us-east-2.amazonaws.com',
  prod: 'https://dorian-client-assets.s3.us-east-2.amazonaws.com',
};

export function getAssetsBaseUrl(envLabel: keyof typeof assetsUrlByEnvLabel) {
  return assetsUrlByEnvLabel[envLabel];
}
