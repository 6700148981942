import { useSearchParams } from 'react-router-dom';
import { RuntimeEnv, getRuntimeEnv } from '../getRuntimeEnv';

const runtimeEnv = getRuntimeEnv();
const envLabel = runtimeEnv === RuntimeEnv.Prod ? 'prod' : 'staging';

const branchioDomainByEnvLabel = {
  local: 'https://dorian.test-app.link',
  unstable1: 'https://dorian.test-app.link',
  unstable2: 'https://dorian.test-app.link',
  staging: 'https://dorian.test-app.link',
  prod: 'https://dorian.app.link',
};

const branchioDomain = branchioDomainByEnvLabel[envLabel];

// cSpell:ignore TNLKM
const fallbackBranchioLinkId = 'TNLKM9deDub';

export const useDownloadDorianURL = () => {
  const [params] = useSearchParams();
  const branchioLinkId = params.get('branchioLinkId') ?? fallbackBranchioLinkId;
  return `${branchioDomain}/${branchioLinkId}`;
};
