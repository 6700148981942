import { useDownloadDorianURL } from '../../../../hooks/useDownloadDorianURL';
import { usePopup } from '../../../../PageLayout/PopupProvider/PopupProvider';

export function useDownloadPopup() {
  const { showPopup, hidePopup } = usePopup();
  const downloadDorianURL = useDownloadDorianURL();

  function showDownloadPopup() {
    showPopup({
      title: 'Access the full Dorian experience by downloading our app',
      primaryButton: {
        label: 'DOWNLOAD DORIAN',
        onClick: () => window.open(downloadDorianURL, '_blank', 'noopener,noreferrer'),
      },
      secondaryButton: {
        label: 'Cancel',
        onClick: hidePopup,
      },
    });
  }

  return showDownloadPopup;
}
