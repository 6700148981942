import { Box, Grid, Typography } from '@mui/material';
import { Text } from '../../../components/Text/Text';
import { AudienceIcon } from '../../../icons/AudienceIcon';
import { ShareIcon } from '../../../icons/ShareIcon';
import { CopyToClipboardButton } from '../../components/CopyToClipboardButton/CopyToClipboardButton';
import cs from './StreamInfoSection.module.css';

const avatarSize = 56;
interface StreamInfoSectionProps {
    eventTitle: string
    storyTitle: string
    episodeTitle: string
    description: string
    coverImageUrl: string
    hostName: string
    hostAvatarUrl: string
    attendeesNumber: number

}

export function StreamInfoSection(props: StreamInfoSectionProps) {
  const {
    eventTitle,
    storyTitle,
    episodeTitle,
    description,
    coverImageUrl,
    hostName,
    hostAvatarUrl,
    attendeesNumber,
  } = props;

  return (
    <Box color="primary.light">
      <Typography variant="h1" mb={3}>{eventTitle}</Typography>
      <Grid container gap={2}>
        <Grid item xs={5}>
          {coverImageUrl && (
            <Box
              component="img"
              src={coverImageUrl}
              alt="cover"
              maxWidth="100%"
            />
          )}
        </Grid>
        <Grid item xs={5} textAlign="left">
          <Typography
            variant="h2"
            color="primary.light"
            mb={1}
          >
            {storyTitle}
          </Typography>
          <Typography
            variant="h3"
            color="primary.light"
            mb={2}
          >
            {episodeTitle}
          </Typography>
          <Typography
            variant="h2"
            color="secondary.main"
            mb={2}
          >
            Hosted by:
          </Typography>
          <Box display="flex" alignItems="center" gap={1} mb={2}>
            <Box
              component="img"
              src={hostAvatarUrl}
              alt="avatar"
              width={avatarSize}
              height={avatarSize}
              className={cs.avatarImage}
              borderRadius="100%"
            />
            <Typography variant="h2" noWrap>{hostName}</Typography>
          </Box>
          <Text variant="h2" maxLineNumber={5}>{description}</Text>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Box display="flex" gap={2} alignItems="center">
          <AudienceIcon fontSize="large" />
          <Typography variant="h1">{attendeesNumber}</Typography>
        </Box>
        <CopyToClipboardButton>
          <ShareIcon fontSize="large" />
        </CopyToClipboardButton>
      </Box>
    </Box>
  );
}
