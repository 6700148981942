import { useLargeVideoSize } from '../Participants/Participant/useLargeVideoSize';
import cs from './StreamIsOver.module.css';

export function StreamIsOver() {
  const largeVideoSize = useLargeVideoSize();
  return (
    <div
      className={cs.container}
      style={{
        width: largeVideoSize,
        height: largeVideoSize,
      }}
    />
  );
}
