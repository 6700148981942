import { mapValues } from 'lodash-es';
import { ChoiceMemoryVariablesValuesByName } from '../../../../dorian-shared/types/stream-socket/Advance';
import { useEpisodeInfo, useStoryVariables } from '../../../../services/s3EngineObjectsCache';

export const useChoiceMemoryVariablesValuesByName = (
  episodeUuid: string,
  advanceVariables: ChoiceMemoryVariablesValuesByName,
  onError?: (error: unknown) => void,
) => {
  const episodeInfo = useEpisodeInfo(episodeUuid, onError);
  const storyVariables = useStoryVariables(episodeInfo?.story);
  return {
    ...mapValues(storyVariables, (variableValue) => variableValue.defaultValue),
    ...advanceVariables,
  };
};
