import { SvgIcon, SvgIconProps } from '@mui/material';

/* eslint-disable max-len */
export function EmojiIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.1924 6.09C18.2054 2 17.0164 2 16.5024 2C16.4116 2.00009 16.321 2.00744 16.2314 2.022C15.3834 2.16 14.2924 3.031 12.9824 4.612H11.0174C9.70942 3.03 8.61741 2.16 7.76941 2.022C7.68015 2.00744 7.58986 2.00008 7.49941 2H7.48641C6.96541 2 5.77941 2.03 3.80641 6.09C1.60041 7.816 -1.52259 12.022 0.848415 17.04C3.19241 22 9.82141 22 12.0004 22C14.1794 22 20.8084 22 23.1524 17.041C25.5234 12.022 22.4004 7.816 20.1924 6.09ZM21.9404 16.49C20.0114 20.571 14.3744 20.69 12.0004 20.69C9.62642 20.69 3.98941 20.569 2.06141 16.49C-0.608585 10.839 4.87941 6.927 4.87941 6.927C4.87941 6.927 6.59241 3.304 7.50041 3.304H7.55141C8.44141 3.449 10.3704 5.912 10.3704 5.912H13.6334C13.6334 5.912 15.5624 3.449 16.4524 3.304H16.5034C17.4034 3.304 19.1224 6.931 19.1224 6.931C19.1224 6.931 24.6104 10.843 21.9404 16.494V16.49Z" fill="white" />
        <path d="M15.8805 14.1572C15.5045 14.2472 14.1884 15.2842 13.9244 15.2842C13.6508 15.2938 13.3787 15.2387 13.1305 15.1232C12.8708 14.99 12.6717 14.763 12.5734 14.4882C12.469 14.1999 12.4185 13.8948 12.4244 13.5882C12.4252 13.4256 12.4363 13.2633 12.4574 13.1022L13.2445 12.3162C13.3156 12.2455 13.3642 12.1552 13.384 12.0568C13.4037 11.9584 13.3938 11.8564 13.3554 11.7637C13.317 11.671 13.252 11.5917 13.1685 11.5361C13.085 11.4805 12.9868 11.4509 12.8865 11.4512H11.1135C11.0113 11.4516 10.9117 11.4829 10.8277 11.5409C10.7436 11.5989 10.679 11.681 10.6424 11.7763C10.6058 11.8716 10.5988 11.9758 10.6223 12.0751C10.6459 12.1745 10.6989 12.2644 10.7745 12.3332L11.5315 13.0192C11.5599 13.2078 11.5743 13.3984 11.5745 13.5892C11.5923 13.9932 11.4953 14.3941 11.2945 14.7452C11.1891 14.9072 11.0424 15.038 10.8695 15.1242C10.6215 15.2396 10.3498 15.2948 10.0765 15.2852C9.81245 15.2852 8.49546 14.2472 8.12046 14.1572C8.07162 14.1446 8.02078 14.142 7.97089 14.1493C7.92101 14.1566 7.87307 14.1738 7.82989 14.1998C7.7867 14.2258 7.74913 14.2601 7.71935 14.3008C7.68957 14.3415 7.6682 14.3877 7.65645 14.4367C7.64471 14.4858 7.64283 14.5366 7.65095 14.5864C7.65906 14.6362 7.677 14.6838 7.70371 14.7266C7.73042 14.7693 7.76538 14.8064 7.80654 14.8355C7.8477 14.8646 7.89424 14.8852 7.94346 14.8962C8.218 14.9772 8.45765 15.1476 8.62446 15.3802C8.62446 15.3802 11.0744 18.5982 12.0364 18.5982C12.9984 18.5982 15.5595 15.2042 15.5595 15.2042C15.5595 15.2042 15.6355 14.9972 16.0595 14.8952C16.1079 14.8836 16.1536 14.8625 16.1939 14.8332C16.2343 14.8039 16.2684 14.767 16.2945 14.7245C16.3205 14.682 16.3379 14.6349 16.3457 14.5856C16.3535 14.5364 16.3516 14.4861 16.34 14.4377C16.3283 14.3892 16.3073 14.3435 16.278 14.3032C16.2487 14.2629 16.2118 14.2287 16.1693 14.2027C16.1268 14.1766 16.0796 14.1592 16.0304 14.1514C15.9812 14.1436 15.9309 14.1455 15.8825 14.1572H15.8805Z" fill="white" />
        <path d="M7.16023 5.11182L6.49023 6.78682L8.16524 6.45182L7.16023 5.11182Z" fill="white" />
        <path d="M16.8434 5.11182L15.8384 6.45182L17.5134 6.78682L16.8434 5.11182Z" fill="white" />
        <path d="M4.97021 11.9552C4.97021 12.2694 5.06339 12.5766 5.23795 12.8379C5.4125 13.0992 5.6606 13.3029 5.9509 13.4232C6.24119 13.5435 6.56064 13.575 6.86885 13.5137C7.17706 13.4525 7.46018 13.3013 7.68245 13.0792C7.90471 12.857 8.05613 12.574 8.11755 12.2658C8.17898 11.9577 8.14766 11.6382 8.02754 11.3478C7.90743 11.0575 7.70393 10.8092 7.44275 10.6345C7.18157 10.4598 6.87444 10.3664 6.56021 10.3662C6.13869 10.3662 5.73442 10.5336 5.43626 10.8316C5.13811 11.1295 4.97048 11.5337 4.97021 11.9552Z" fill="white" />
        <path d="M17.1422 13.5442C17.4565 13.5442 17.7637 13.451 18.025 13.2764C18.2863 13.1018 18.49 12.8536 18.6103 12.5633C18.7305 12.2729 18.762 11.9535 18.7007 11.6452C18.6394 11.337 18.488 11.0538 18.2658 10.8316C18.0436 10.6094 17.7604 10.4581 17.4522 10.3967C17.144 10.3354 16.8245 10.3669 16.5341 10.4872C16.2438 10.6074 15.9956 10.8111 15.821 11.0724C15.6464 11.3337 15.5532 11.6409 15.5532 11.9552C15.5532 12.3766 15.7206 12.7808 16.0186 13.0788C16.3166 13.3768 16.7208 13.5442 17.1422 13.5442Z" fill="white" />
      </svg>
    </SvgIcon>
  );
}
