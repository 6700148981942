import { GameplayRenderer, SIDE } from '@dorian/gameplay-renderer';
import { CharacterVisualProperties } from '../../../../../../dorian-shared/types/character/CharacterVisualProperties';
import { ExpressionValue } from '../../../../../../dorian-shared/types/character/ExpressionValue';

export interface PropertiesWithExpression extends Omit<CharacterVisualProperties, 'expression'> {
  expression: ExpressionValue
}

export async function handleCharacterChange(
  gameplayRenderer: GameplayRenderer,
  propertiesWithExpression: PropertiesWithExpression,
  previousSide: SIDE.LEFT | SIDE.RIGHT | undefined,
  side: SIDE.LEFT | SIDE.RIGHT,
  isSameCharacterAndSide: boolean,
) {
  if (isSameCharacterAndSide) {
    await gameplayRenderer.updateAvatar(propertiesWithExpression, side, true);
  } else {
    if (previousSide) {
      await gameplayRenderer.hideAvatar(previousSide, true);
    }
    await gameplayRenderer.updateAvatar(propertiesWithExpression, side, true);
  }
}
