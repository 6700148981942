import { VotesData } from '@dorian/gameplay-renderer/dist/models/VotesData';
import { EpisodeContentResponseData } from '../../../dorian-shared/types/multiplayerServer/EpisodeContentResponseData';
import { Advance } from '../../../dorian-shared/types/stream-socket/Advance';
import { VotingResult } from '../../../dorian-shared/types/stream-socket/VoteEndSocketMessage';
import { BaseParticipant } from '../types/BaseParticipant';
import { StreamState } from '../types/StreamState';
import { getStepDetails } from './hooks/getStepDetails';

export function sortParticipants(participant1: BaseParticipant, participant2: BaseParticipant) {
  if (participant1.host) {
    return -1;
  }
  if (participant2.host) {
    return 1;
  }

  if (participant1.canBroadcast) {
    return -1;
  }

  if (participant2.canBroadcast) {
    return 1;
  }

  return 0;
}

export function convertParticipantsToMapById(participants: BaseParticipant[]) {
  return participants.reduce((participantsById, participant) => ({
    ...participantsById,
    [participant.playerId]: participant,
  }), {});
}

const FIRST_BRANCH_KEY = 'intro';

export function getStreamDataFromAdvanceAndContent(advance: Advance, episodeContent: EpisodeContentResponseData) {
  const isInitialCountdownShouldStart = advance.branch === FIRST_BRANCH_KEY && advance.index === 0;

  if (isInitialCountdownShouldStart) {
    return {
      state: StreamState.InitialCountdown,
      advance,
    };
  }

  const { step } = getStepDetails(episodeContent, undefined, advance.branch, advance.index);
  const isAdvanceOutOfBounds = !step;
  if (isAdvanceOutOfBounds) {
    return {
      state: StreamState.AfterEpisodeLobby as const, // probably TS issue
    };
  }

  return {
    state: StreamState.InProgress,
    advance,
  };
}

export function prepareVotingResults(votingResults: VotingResult[]) {
  const voterAvatarsByAnswerIndex: VotesData['votersAvatarsArr'] = [];
  const voteCountsByAnswerIndex: VotesData['votesCountsArr'] = [];

  votingResults.forEach((votingResult) => {
    const answerIndex = votingResult.answer;
    voterAvatarsByAnswerIndex[answerIndex] = votingResult.players.map((player) => player.avatar_url);

    const broadcasterPlayersCount = votingResult.players.filter((player) => player.isBroadcaster).length;
    const otherPlayerCount = votingResult.votes - broadcasterPlayersCount;
    voteCountsByAnswerIndex[answerIndex] = {
      count: otherPlayerCount,
      broadcasterPlayersCount,
    };
  });
  return {
    votersAvatarsArr: voterAvatarsByAnswerIndex,
    votesCountsArr: voteCountsByAnswerIndex,
  };
}
