import axios from 'axios';
import { RuntimeEnv, getRuntimeEnv } from '../getRuntimeEnv';

const mediaServerUrlByEnvLabel = {
  staging: 'https://media.dev.test.4dorian.net',
  prod: 'https://media.staging.test.4dorian.net',
};

const runtimeEnv = getRuntimeEnv();
const envLabel = runtimeEnv === RuntimeEnv.Prod ? 'prod' : 'staging';

export const mediaServerUrl = mediaServerUrlByEnvLabel[envLabel];

export const mediaServer = axios.create({
  baseURL: `${mediaServerUrl}/api`,
});
