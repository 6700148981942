import { SvgIcon, SvgIconProps } from '@mui/material';

/* eslint-disable max-len */
export function ShareIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M14.757 6.41619V4.31019C14.7442 4.0573 14.8086 3.8065 14.9418 3.59114C15.075 3.37578 15.2706 3.20606 15.5025 3.10456C15.7345 3.00307 15.9919 2.9746 16.2405 3.02294C16.489 3.07128 16.717 3.19414 16.894 3.37519L22.634 9.45319C22.8692 9.70717 22.9998 10.0406 22.9998 10.3867C22.9998 10.7328 22.8692 11.0662 22.634 11.3202L16.894 17.3932C16.7183 17.5762 16.4909 17.7011 16.2422 17.7511C15.9935 17.8011 15.7355 17.7738 15.5028 17.6729C15.27 17.572 15.0737 17.4023 14.9403 17.1866C14.8068 16.9709 14.7425 16.7195 14.756 16.4662V14.2282C8.503 14.2282 4.126 16.3472 1 20.9812C2.252 14.3602 6.004 7.74019 14.757 6.41619Z" fill="white" />
    </SvgIcon>
  );
}
