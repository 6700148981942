import { GameUIStyle } from '@dorian/gameplay-renderer/dist/models/GameUIStyle';
import React, { useEffect, useState } from 'react';
import { CharactersResponseData } from '../../../../dorian-shared/types/multiplayerServer/CharactersResponseData';
import {
  EpisodeContentResponseData,
} from '../../../../dorian-shared/types/multiplayerServer/EpisodeContentResponseData';
import { getEpisodeInfo, s3EngineObjectsCache } from '../../../../services/s3EngineObjectsCache';
import { getStepDetails } from '../hooks/getStepDetails';
import {
  GameplayRendererWrapper,
  GameplayRendererWrapperProps,
} from './GameplayRendererWrapper/GameplayRendererWrapper';
import { useCharactersPropertiesToPreload } from './useCharactersPropertiesToPreload';
import { useVotingResults } from './useVotingResults';

export interface GameplayProps extends Pick <
    GameplayRendererWrapperProps,
    'width' |
    'height' |
    'playerName' |
    'choiceMemoryVariablesValuesByName'
    > {
  episodeUuid: string
  episodeContent: EpisodeContentResponseData | undefined
  branchName: string
  stepIndex: number
}

export function Gameplay(props: GameplayProps) {
  const {
    episodeUuid,
    episodeContent,
    branchName,
    stepIndex,
    ...gameplayRendererWrapperProps
  } = props;

  const [charactersByAlias, setCharactersByAlias] = useState<CharactersResponseData>();
  const [gameUIStyle, setGameUIStyle] = useState<GameUIStyle>(GameUIStyle.Default);
  useEffect(() => {
    if (episodeUuid) {
      (async () => {
        const episodeInfoResponse = await getEpisodeInfo(episodeUuid);
        const { story: bookUuid, bookStyle } = episodeInfoResponse.data;
        setGameUIStyle(bookStyle?.alias === 'arcana_style' ? GameUIStyle.Arcana : GameUIStyle.Default);

        const response = await s3EngineObjectsCache.get<CharactersResponseData>(
          `/getCharacters/${bookUuid}?t=${Date.now()}`,
        );
        setCharactersByAlias(response.data);
        // TODO: handle error
      })();
    }
  }, [episodeUuid]);

  const {
    step,
    scene,
    character,
  } = getStepDetails(episodeContent, charactersByAlias, branchName, stepIndex);

  const charactersPropertiesToPreload = useCharactersPropertiesToPreload(
    episodeContent,
    charactersByAlias,
    step,
    branchName,
  );
  const votingResults = useVotingResults();

  return step && scene ? (
    <GameplayRendererWrapper
      step={step}
      scene={scene}
      character={character}
      charactersPropertiesToPreload={charactersPropertiesToPreload}
      votingResults={votingResults}
      gameUIStyle={gameUIStyle}
      {...gameplayRendererWrapperProps}
    />
  ) : null;
}
