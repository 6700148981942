import { isEqual, mapValues } from 'lodash-es';
import { CharacterVisualProperties } from '../../../../../../dorian-shared/types/character/CharacterVisualProperties';
import {
  DefaultCharacterVisualProperties,
} from '../../../../../../dorian-shared/types/character/DefaultCharacterVisualProperties';

export interface PrepareAuthorChoiceAvatarPropsCharacter {
  properties: CharacterVisualProperties
  forcedProperties?: Partial<DefaultCharacterVisualProperties>
  isDefaultProps?: boolean
}

function getAuthorChoiceProperties(mainCharacter: PrepareAuthorChoiceAvatarPropsCharacter) {
  if (!mainCharacter) {
    return {};
  }

  if (mainCharacter.isDefaultProps) {
    return {};
  }

  const forcedPropertiesProcessed = mapValues(
    mainCharacter.forcedProperties,
    (value) => (value === 'removed' ? null : value),
  );
  const isLegacyDefaultAvatar = isEqual(mainCharacter.properties, forcedPropertiesProcessed);
  if (isLegacyDefaultAvatar) {
    return {};
  }

  return mainCharacter.properties;
}

export function prepareAuthorChoiceAvatarProps(
  character: PrepareAuthorChoiceAvatarPropsCharacter,
  dorianAvatarProperties: DefaultCharacterVisualProperties,
) {
  const authorChoiceProperties = getAuthorChoiceProperties(character);
  const hasAuthorChoiceProperties = Object.keys(authorChoiceProperties).length > 0;

  if (!hasAuthorChoiceProperties) {
    return {
      ...dorianAvatarProperties,
      ...character.forcedProperties,
    };
  }

  return {
    ...character.properties,
    ...character.forcedProperties, // if character is custom there won't be any forcedProperties
  };
}
