import Alert, { AlertProps } from '@mui/material/Alert/Alert';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import {
  PropsWithChildren, createContext, useCallback, useContext, useMemo, useRef, useState,
} from 'react';

interface ToastOptions {
  text: string,
  severity?: AlertProps['severity']
}
interface ToastContext {
  showToast: (toastOptions: ToastOptions) => void
  closeToast: () => void
}

export const toastContext = createContext<ToastContext>({
  showToast: () => {
    throw new Error('ToastContext used outside of provider');
  },
  closeToast: () => {
    throw new Error('ToastContext used outside of provider');
  },
});

export const useToast = () => useContext(toastContext);

type Severity = AlertProps['severity'];
const initialSeverity: Severity = 'success';

const { Provider } = toastContext;
type ToastProviderProps = PropsWithChildren

export function ToastProvider(props: ToastProviderProps) {
  const { children } = props;

  const severityRef = useRef<Severity>(initialSeverity);
  const [alertText, setAlertText] = useState('');
  const closeToast = useCallback(() => {
    setAlertText('');
    severityRef.current = initialSeverity;
  }, []);
  const showToast = useCallback((toastOptions: ToastOptions) => {
    const { text, severity } = toastOptions;
    severityRef.current = severity;
    setAlertText(text);
  }, []);

  const contextValue = useMemo(() => ({
    showToast,
    closeToast,
  }), [
    showToast,
    closeToast,
  ]);
  return (
    <Provider value={contextValue}>
      {children}
      <Snackbar
        open={Boolean(alertText)}
        autoHideDuration={6000}
        onClose={closeToast}
      >
        <Alert severity={severityRef.current}>
          {alertText}
        </Alert>
      </Snackbar>
    </Provider>
  );
}
