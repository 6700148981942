import { SvgIcon, SvgIconProps } from '@mui/material';

/* eslint-disable max-len */
export function SoundOnIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_12452_53662)">
          <path d="M8.98 7.4502H1.78C1.34922 7.4502 1 7.79941 1 8.2302V15.4302C1 15.861 1.34922 16.2102 1.78 16.2102H8.98C9.41078 16.2102 9.76 15.861 9.76 15.4302V8.2302C9.76 7.79941 9.41078 7.4502 8.98 7.4502Z" />
          <path d="M12.74 22.68L3.94995 11.84L12.74 1V22.68Z" />
          <path d="M18.82 11.83C18.82 14.62 17.64 17.07 15.75 18.73L16.38 19.95C18.8 18.08 20.34 15.16 20.34 11.83C20.34 8.49996 18.81 5.58996 16.39 3.70996L15.76 4.92996C17.64 6.58996 18.82 9.03996 18.82 11.82V11.83Z" />
          <path d="M15.7699 11.83C15.7699 13.46 15.2499 14.95 14.3899 16.11L15.0799 17.44C16.4499 16.01 17.2899 14.02 17.2899 11.83C17.2899 9.63998 16.4399 7.65998 15.0799 6.22998L14.3899 7.55998C15.2499 8.71998 15.7699 10.21 15.7699 11.83Z" />
          <path d="M17.61 1.33984L17 2.51984C19.96 4.59984 21.86 7.95984 21.86 11.8298C21.86 15.6998 19.96 19.0598 16.99 21.1398L17.6 22.3198C21.11 20.0398 23.37 16.2298 23.37 11.8198C23.37 7.40984 21.12 3.61984 17.61 1.33984Z" />
        </g>
        <defs>
          <clipPath id="clip0_12452_53662">
            <rect width="22.38" height="21.68" transform="translate(1 1)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
