import { VotesData } from '@dorian/gameplay-renderer/dist/models/VotesData';
import { useCallback, useEffect, useState } from 'react';
import { VoteEndSocketMessage } from '../../../../dorian-shared/types/stream-socket/VoteEndSocketMessage';
import { useMultiplayerContext } from '../../../../providers/MultiPlayerProvider';
import { MultiplayerEvent } from '../../../../services/multiplayerService/multiplayerService';
import { prepareVotingResults } from '../streamUtils';

export function useVotingResults() {
  const [votingResults, setVotingResults] = useState<VotesData>();
  const multiplayerService = useMultiplayerContext();

  const handleVoteEnd = useCallback((data: VoteEndSocketMessage) => {
    const votingResultsPart = prepareVotingResults(data.votingResults);
    setVotingResults({
      ...votingResultsPart,
      winningAnswer: data.winningAnswer,
    });
  }, []);

  useEffect(() => {
    multiplayerService.addListener(MultiplayerEvent.VoteEnd, handleVoteEnd);
    return () => {
      multiplayerService.removeListener(MultiplayerEvent.VoteEnd, handleVoteEnd);
    };
  }, [handleVoteEnd, multiplayerService]);
  return votingResults;
}
