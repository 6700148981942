import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton';
import { SecondaryButton } from '../../../components/SecondaryButton/SecondaryButton';
import cs from './Popup.module.css';

interface Button {
    onClick: () => void
    label: string
}

export interface PopupProps {
    title: string
    primaryButton: Button
    secondaryButton?: Button
}

export function Popup(props: PopupProps) {
  const {
    title,
    primaryButton,
    secondaryButton,
  } = props;
  return (
    <div className={cs.overlay}>
      <div className={cs.popup}>
        <div className={cs.title}>{title}</div>
        <div className={cs.buttonBar}>
          <PrimaryButton onClick={primaryButton.onClick}>{primaryButton.label}</PrimaryButton>
          {secondaryButton && (
          <SecondaryButton onClick={secondaryButton.onClick}>
            {secondaryButton.label}
          </SecondaryButton>
          )}
        </div>
      </div>
    </div>
  );
}
