import Alert from '@mui/material/Alert/Alert';
import IconButton from '@mui/material/IconButton/IconButton';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import copyToClipboard from 'copy-to-clipboard';
import { PropsWithChildren } from 'react';
import { useToggle } from '../../../dorian-shared/hooks/useToggle';

type CopyToClipboardButtonProps = PropsWithChildren

export function CopyToClipboardButton(props: CopyToClipboardButtonProps) {
  const { children } = props;
  const [isSnackbarOpened, openSnackbar, closeSnackbar] = useToggle();
  const handleClick = () => {
    copyToClipboard(window.location.href);
    openSnackbar();
  };
  return (
    <>
      <Snackbar
        open={isSnackbarOpened}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        <Alert severity="success">
          Share link copied to clipboard!
        </Alert>
      </Snackbar>
      <IconButton onClick={handleClick} size="small">
        {children}
      </IconButton>
    </>
  );
}
