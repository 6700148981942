import clsx from 'clsx';
import { HTMLProps } from 'react';
import cs from './Spinner.module.css';

export function Spinner(props: HTMLProps<HTMLDivElement>) {
  const { className, ...otherProps } = props;
  return (
    <div className={clsx(cs.spinner, className)} {...otherProps}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
