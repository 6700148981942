import { useCallback, useEffect, useState } from 'react';
import { Countdown } from '../../../../dorian-shared/components/Countdown/Countdown';
import { getSizeConfig } from '../../../../dorian-shared/getSizeConfig';
import { useMultiplayerContext } from '../../../../providers/MultiPlayerProvider';
import { MultiplayerEvent } from '../../../../services/multiplayerService/multiplayerService';
import cs from './VotingCountdown.module.css';

enum VotingCountdownState {
    None = 'None',
    Ticking = 'Ticking',
    Finished = 'Finished',
}

const TIMER_SECONDS = 10;

export function VotingCountdown() {
  const [votingCountdownState, setVotingCountdownState] = useState<VotingCountdownState>(VotingCountdownState.None);
  const multiplayerService = useMultiplayerContext();

  const handleVoteStart = useCallback(() => {
    setVotingCountdownState(VotingCountdownState.Ticking);
  }, []);

  const handleVoteEnd = useCallback(() => {
    setVotingCountdownState(VotingCountdownState.Finished);
  }, []);

  useEffect(() => {
    multiplayerService.addListener(MultiplayerEvent.VoteStart, handleVoteStart);
    multiplayerService.addListener(MultiplayerEvent.VoteEnd, handleVoteEnd);
    return () => {
      multiplayerService.removeListener(MultiplayerEvent.VoteStart, handleVoteStart);
      multiplayerService.removeListener(MultiplayerEvent.VoteEnd, handleVoteEnd);
    };
  }, [handleVoteEnd, handleVoteStart, multiplayerService]);

  const sizeConfig = getSizeConfig();
  const countdownStyle = {
    width: `${sizeConfig.width / 4}px`,
    height: `${sizeConfig.width / 4}px`,
    marginLeft: `${sizeConfig.width / 30}px`,
  };
  return votingCountdownState === VotingCountdownState.Ticking
    ? (
      <div className={cs.container} style={countdownStyle}>
        <Countdown initialSeconds={TIMER_SECONDS - 1} />
      </div>
    )
    : null;
}
