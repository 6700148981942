import { Spinner } from '../../../../components/Spinner/Spinner';
import { useLargeVideoSize } from '../Participants/Participant/useLargeVideoSize';
import cs from './WaitingPanel.module.css';

export function WaitingPanel() {
  const largeVideoSize = useLargeVideoSize();
  return (
    <div
      className={cs.container}
      style={{
        width: largeVideoSize,
        height: largeVideoSize,
      }}
    >
      <Spinner className={cs.spinner} />
    </div>
  );
}
