import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  ScheduledEventStatus,
} from '../../dorian-shared/types/apiServer/getScheduledEventWeb/ScheduledEventResponseData';
import { useFetchStreamStatusByInterval } from '../../hooks/useFetchStreamStatusByInterval';
import { ActiveStreamPageV2 } from '../../pages/ActiveStreamPage/ActiveStreamPageV2';
import { fetchMultiplayerStatus } from '../../pages/ActiveStreamPage/fetchMultiplayerStatus';
import { StreamPage, StreamPageProps } from '../../pages/StreamPage/StreamPage';
import { useEpisodeInfo } from '../../services/s3EngineObjectsCache';
import { fetchScheduledStreamData } from '../../utils/fetchScheduledStreamData';
import { getCoverUrl } from '../../utils/getCoverUrl';

enum ViewModeSate {
  Initial = 'initial',
  Cover = 'Cover',
  Live = 'live',
}

const getEventTimeTitle = (startTime: string | undefined) => {
  if (startTime) {
    const startDate = parseISO(startTime);
    return format(startDate, 'MMMM do, H:mmaaa O');
  }
  return '';
};

export function StreamManager() {
  const [viewModeSate, setModeState] = useState<ViewModeSate>(ViewModeSate.Initial);
  const [episodeId, setEpisodeId] = useState('');

  const [searchParams] = useSearchParams();

  const multiplayerIdForced = searchParams.get('multiplayerId');
  const scheduledStreamId = searchParams.get('scheduledStreamId');

  const { scheduledStreamData } = useFetchStreamStatusByInterval(
    multiplayerIdForced ? null : Number(scheduledStreamId),
  );

  const multiplayerId = Number(searchParams.get('multiplayerId')) || scheduledStreamData?.multiplayerId;

  useEffect(() => {
    const viewMode = searchParams.get('view');
    switch (viewMode) {
      case 'cover':
        setModeState(ViewModeSate.Cover);
        break;
      case 'live':
        setModeState(ViewModeSate.Live);
        break;
      default:
        setModeState(ViewModeSate.Live);
    }
  }, [searchParams]);

  useEffect(() => {
    fetchScheduledStreamData(Number(scheduledStreamId)).then(
      (scheduledStreamData) => {
        if (scheduledStreamData) {
          setEpisodeId(scheduledStreamData.episodeId);
        }
      },
    );
  }, [viewModeSate, scheduledStreamId]);

  useEffect(() => {
    if (multiplayerId) {
      fetchMultiplayerStatus(multiplayerId).then((newMultiplayerStatus) => {
        setEpisodeId(newMultiplayerStatus.episodeId);
      });
    }
  }, [multiplayerId]);

  const episodeInfo = useEpisodeInfo(episodeId);

  const eventTimeTitle = getEventTimeTitle(scheduledStreamData?.startTime);
  const isStreamFinished = scheduledStreamData?.status === ScheduledEventStatus.Finished
  || (!scheduledStreamData && !episodeInfo);

  const isInProgress = scheduledStreamData?.status === ScheduledEventStatus.InProgress;
  const isShowLive = (
    viewModeSate === ViewModeSate.Live && isInProgress
  )
  || (
    Boolean(multiplayerIdForced) && !isStreamFinished
  );
  const showEventTimeTitle = isStreamFinished ? 'Stream Has Ended' : eventTimeTitle;
  const eventTitle = scheduledStreamData?.eventTitle ?? episodeInfo?.title;

  const streamPageProps: StreamPageProps = {
    coverImageURL: getCoverUrl(episodeInfo?.bookCover.medium),
    eventTimeTitle: isInProgress ? '' : showEventTimeTitle,
    eventTitle: eventTitle ?? '',
    hostUsername: scheduledStreamData?.hostUsername ?? '',
    hostAvatarUrl: scheduledStreamData?.avatarUrl ?? '',
    isFinished: isStreamFinished,
  };

  return (
    <span>
      {!isShowLive && (
        <StreamPage {...streamPageProps} />
      )}
      {isShowLive && multiplayerId && (
      <ActiveStreamPageV2 multiplayerId={multiplayerId} eventTitle={eventTitle} />
      )}
    </span>
  );
}
