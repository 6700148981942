import { GameplayRenderer, SIDE } from '@dorian/gameplay-renderer';
import { STORY_STATE } from '@dorian/gameplay-renderer/dist/consts';
import { useEffect } from 'react';
import { usePrevious } from '../../../../../../dorian-shared/hooks/usePrevious';
import {
  CharacterPlacementSide, Step, StepCharacter,
} from '../../../../../../dorian-shared/types/multiplayerServer/EpisodeContentResponseData';
import { StreamState } from '../../../../../../dorian-shared/types/multiplayerServer/StreamState';
import { ChoiceMemoryVariablesValuesByName } from '../../../../../../dorian-shared/types/stream-socket/Advance';
import { handleCharacterChange } from './handleCharacterChange';
import { prepareAnswer } from './prepareAnswer';
import {
  PrepareAuthorChoiceAvatarPropsCharacter,
  prepareAuthorChoiceAvatarProps,
} from './prepareAuthorChoiceAvatarProps';
import { renderTextBubble } from './renderTextBubble';
import { replaceChoiceMemoryVariables, replaceChoiceMemoryVariablesInAnswers } from './replaceChoiceMemoryVariables';
import { replacePlayerNamePlaceholder, replacePlayerNamePlaceholderInAnswers } from './replacePlayerNamePlaceholders';

const defaultUserAvatarProperties = {
  type: 'female' as const,
  clothing: 'normal',
  skincolor: 'tan1',
  hairstyle: 'shortcut_black',
  headshape: 'triangle',
  eyebrowshape: 'arched',
  eyestyle: 'downturned_brown',
  noseshape: 'wide1',
  lipcolor: 'brightred',
  underwearstyle: 'basic_black',
  topinnerstyle: 'simplecamisole_black',
  bottomstyle: 'pants_black',
  swimwearstyle: 'fancy_black',
  eyebrowcolor: 'black',
  outfitstyle: 'jumpsuit_red',
};

async function immediatelyHideBothAvatars(gameplayRenderer: GameplayRenderer) {
  await Promise.any([
    gameplayRenderer.hideAvatar(SIDE.LEFT, true),
    gameplayRenderer.hideAvatar(SIDE.RIGHT, true),
  ]);
}

// TS doesn't understand that SIDE.CENTER is not an option here
const gameplayRendererSidesByCharacterPlacementSides: Record<CharacterPlacementSide, SIDE.RIGHT | SIDE.LEFT> = {
  [CharacterPlacementSide.Left]: SIDE.LEFT,
  [CharacterPlacementSide.Right]: SIDE.RIGHT,
};

function getRenderSide(
  isPlayer: boolean,
  characterPlacementSide: CharacterPlacementSide | undefined,
) {
  const defaultSide = isPlayer ? SIDE.RIGHT : SIDE.LEFT;
  return characterPlacementSide
    ? gameplayRendererSidesByCharacterPlacementSides[characterPlacementSide]
    : defaultSide;
}

export interface UseStepRenderCharacter extends PrepareAuthorChoiceAvatarPropsCharacter {
  name: string
  isPlayer: boolean
  hasForceAuthorsChoiceName: boolean
}

interface GetReplacedCharacterNameParams {
  isPlayer: boolean,
  authorCharacterName: string,
  hasForceAuthorsChoiceName: boolean,
  playerName: string,
}

function getReplacedCharacterName(params: GetReplacedCharacterNameParams) {
  const {
    isPlayer,
    authorCharacterName,
    hasForceAuthorsChoiceName,
    playerName,
  } = params;
  const isPlayerNameUsed = isPlayer || !hasForceAuthorsChoiceName;
  return isPlayerNameUsed ? playerName : authorCharacterName;
}

export function useStepRender(
  gameplayRenderer: GameplayRenderer | undefined,
  step: Pick<Step, 'answers' | 'type' | 'text'>,
  stepCharacter: Pick<StepCharacter, 'expression' | 'characterPlacementSide'> | undefined,
  character: UseStepRenderCharacter | undefined,
  playerName: string,
  choiceMemoryVariablesValuesByName: ChoiceMemoryVariablesValuesByName | undefined,
) {
  const previousCharacter = usePrevious(character);
  const previousStepCharacterPlacementSide = usePrevious(stepCharacter?.characterPlacementSide);

  useEffect(() => {
    (async () => {
      if (!gameplayRenderer) {
        return null;
      }

      const { type: stepType, answers, text } = step;
      const replacedWithPlayerNameText = replacePlayerNamePlaceholder(text, playerName);
      const replacedWithChoiceMemoryVariablesText = choiceMemoryVariablesValuesByName
        ? replaceChoiceMemoryVariables(replacedWithPlayerNameText, choiceMemoryVariablesValuesByName)
        : replacedWithPlayerNameText;
      const textBubbleProps = {
        // TODO: improve mapping from server states to GR states
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        type: STORY_STATE[stepType] as STORY_STATE,
        text: replacedWithChoiceMemoryVariablesText,
      };

      switch (stepType) {
        case StreamState.Ending:
        case StreamState.Narrator: {
          await immediatelyHideBothAvatars(gameplayRenderer);
          return renderTextBubble(gameplayRenderer, textBubbleProps);
        }

        case StreamState.Texting:
        case StreamState.Reaction:
        case StreamState.Thinking:
        case StreamState.Dialogue:
        case StreamState.Choice: {
          if (!stepCharacter || !character) {
            // TODO: sentry
            return null;
          }
          const { characterPlacementSide } = stepCharacter;
          const {
            isPlayer,
            name,
            hasForceAuthorsChoiceName,
          } = character;
          const side = getRenderSide(isPlayer, characterPlacementSide);
          const replacedCharacterName = getReplacedCharacterName({
            isPlayer,
            authorCharacterName: name,
            hasForceAuthorsChoiceName,
            playerName,
          });
          const stepWithCharacterTextBubbleProps = {
            ...textBubbleProps,
            side,
            characterName: replacedCharacterName,
          };

          if (stepType === StreamState.Texting) {
            await immediatelyHideBothAvatars(gameplayRenderer);
            return renderTextBubble(gameplayRenderer, stepWithCharacterTextBubbleProps);
          }

          const { expression } = stepCharacter;
          const { properties } = character;
          const previousSide = previousCharacter
            ? getRenderSide(previousCharacter.isPlayer, previousStepCharacterPlacementSide)
            : undefined;
          const isSameCharacterAndSide = previousCharacter?.name === name && side === previousSide;

          const propertiesWithoutExpression = isPlayer
            ? prepareAuthorChoiceAvatarProps(character, defaultUserAvatarProperties)
            : properties;

          const propertiesWithExpression = {
            ...propertiesWithoutExpression,
            expression,
          };

          // GR doesn't resolve this promises
          // if this function have been called again with newer character
          handleCharacterChange(
            gameplayRenderer,
            propertiesWithExpression,
            previousSide,
            side,
            isSameCharacterAndSide,
          );

          switch (stepType) {
            case StreamState.Reaction:
              return gameplayRenderer.hideTextBubble();

            case StreamState.Choice: {
              if (!answers) {
                // TODO: sentry
                return null;
              }

              const preparedAnswers = answers.map(prepareAnswer);
              const replacedWithPlayerNameAnswers = replacePlayerNamePlaceholderInAnswers(preparedAnswers, playerName);
              const replacedWithChoiceMemoryVariablesAnswers = choiceMemoryVariablesValuesByName
                ? replaceChoiceMemoryVariablesInAnswers(
                  replacedWithPlayerNameAnswers,
                  choiceMemoryVariablesValuesByName,
                )
                : replacedWithPlayerNameAnswers;
              const textBubbleProps = {
                ...stepWithCharacterTextBubbleProps,
                answers: replacedWithChoiceMemoryVariablesAnswers,
              };
              return renderTextBubble(
                gameplayRenderer,
                textBubbleProps,
              );
            }

            case StreamState.Dialogue:
            case StreamState.Thinking:
              return renderTextBubble(
                gameplayRenderer,
                stepWithCharacterTextBubbleProps,
              );
            default:
              // TODO: sentry
              return null;
          }
        }

        default:
          // TODO: sentry
          return null;
      }
    })();
    // previousCharacter is ref value we don't need trigger renderStep on it's change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameplayRenderer, step, character, choiceMemoryVariablesValuesByName]);
}
