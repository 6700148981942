import { GameplayRenderer, UPDATE_TYPE } from '@dorian/gameplay-renderer';
import { STORY_STATE } from '@dorian/gameplay-renderer/dist/consts';
import { TextBubbleProps } from '@dorian/gameplay-renderer/dist/models/TextBubbleProps';

export async function renderTextBubble(
  gameplayRenderer: GameplayRenderer,
  textBubbleProps: TextBubbleProps,
) {
  await gameplayRenderer.displayTextBubble(textBubbleProps);
  if (textBubbleProps.answers) {
    await gameplayRenderer.updateTextBubble({
      type: STORY_STATE.Choice,
      updateType: UPDATE_TYPE.ANSWER_DATA,
      answers: textBubbleProps.answers,
      settings: {
        isHeartsDisabled: false,
        isTraitsHidden: true,
        isVisibleComments: false,
      },
    });
  }
}
