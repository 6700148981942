import { ANSWER_TYPES, COST_TYPE, Answer as GRAnswer } from '@dorian/gameplay-renderer';
import { Answer, AnswerType } from '../../../../../../dorian-shared/types/multiplayerServer/EpisodeContentResponseData';

const gameRendererAnswerTypesByAPIAnswerType = {
  [AnswerType.Free]: ANSWER_TYPES.FREE,
  [AnswerType.Passive]: ANSWER_TYPES.FREE,
  [AnswerType.Paid]: ANSWER_TYPES.PAID,
  [AnswerType.Romantic]: ANSWER_TYPES.ROMANTIC,
  [AnswerType.Erotic]: ANSWER_TYPES.EROTIC,
};
const CHOICE_ICONS = {
  coin: 'assets/img/ui/icon-coin.svg',
  heart: 'assets/img/ui/icon-heart.svg',
  erotic: 'assets/img/badges/icon-romantic-choice.png',
  romantic: 'assets/img/badges/icon-romantic-choice.png',
};
const specialIconsByByAPIAnswerType = {
  [AnswerType.Free]: undefined,
  [AnswerType.Passive]: undefined,
  [AnswerType.Paid]: undefined,
  [AnswerType.Romantic]: CHOICE_ICONS.romantic,
  [AnswerType.Erotic]: CHOICE_ICONS.erotic,
};
const traitColorByByAPIAnswerType = {
  [AnswerType.Free]: '#2C2C2C',
  [AnswerType.Passive]: '#2C2C2C',
  [AnswerType.Paid]: '#2C2C2C',
  [AnswerType.Romantic]: '#F56969',
  [AnswerType.Erotic]: '#F56969',
};

function getTraitColor(answer: Answer, isBonusContent: boolean, cost: number) {
  const { type } = answer;
  if (isBonusContent) {
    return cost === 0 ? 'var(--gradient-primary)' : 'transparent';
  }
  return traitColorByByAPIAnswerType[type];
}

function getCostProps(level: number) {
  return {
    cost: level,
    costType: level > 0 ? COST_TYPE.HEART : COST_TYPE.FREE,
  };
}

export function prepareAnswer(answer: Answer, index: number): GRAnswer {
  // TODO: extend logic here to replicate client
  // TODO: fetch costs form server in getCostProps
  // TODO: validate what Active type of answer
  const isBonusContent = answer.modifiers?.some((modifier) => modifier.id === 'bonus_content') ?? false;
  const { cost, costType } = getCostProps(answer.data?.level ?? 0);
  return {
    text: answer.text,
    goto: answer.goto,
    num: index,
    costType,
    cost,
    type: gameRendererAnswerTypesByAPIAnswerType[answer.type] ?? ANSWER_TYPES.FREE,
    trait_color: getTraitColor(answer, isBonusContent, cost),
    gold_border: answer.type === 'paid',
    bonusContent: isBonusContent,
    special_icon: specialIconsByByAPIAnswerType[answer.type],
  };
}
