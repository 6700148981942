import { useCountdown } from '../../../../dorian-shared/components/Countdown/useCountdown';
import { useLargeVideoSize } from '../Participants/Participant/useLargeVideoSize';
import cs from './StreamStartCountdown.module.css';

const STREAM_START_COUNTDOWN_SECONDS = 5;
interface StreamStartCountdownProps {
  onCountdownEnd: () => void
}

export function StreamStartCountdown(props: StreamStartCountdownProps) {
  const {
    onCountdownEnd,
  } = props;
  const largeVideoSize = useLargeVideoSize();
  const secondsLeft = useCountdown(
    STREAM_START_COUNTDOWN_SECONDS,
    onCountdownEnd,
  );
  return (
    <div
      className={cs.container}
      style={{
        width: largeVideoSize,
        height: largeVideoSize,
      }}
    >
      {secondsLeft > 0 ? (
        <>
          <div>Starting in:</div>
          <div>{secondsLeft}</div>
        </>
      ) : (
        <div>STARTING!</div>
      )}
    </div>
  );
}
