import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { ThemeProvider as MUIStylesThemeProvider } from '@mui/styles';
import React, { CSSProperties, PropsWithChildren } from 'react';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Bold: true;
    h2Bold: true;
    h2TitleBold: true;
    h3Medium: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  h1Bold: CSSProperties;
  h2Bold: CSSProperties;
  h2TitleBold: CSSProperties;
  h3Medium: CSSProperties;
}

const theme = createTheme({
  spacing: (factor: number) => `${0.5 * factor}rem`,
  typography: {
    button: {
      textTransform: 'none',
    },
    h2Bold: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 700,
      fontSize: '30px',
      color: '#828BE1',
    },
  } as ExtendedTypographyOptions,
  palette: {
    primary: {
      // TODO: probably we should use custom, since these are 3 different colors
      light: '#FFFFFF',
      main: '#F7A397',
      dark: '#1C1C1C',
    },
    secondary: {
      main: '#A8A8A8',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltipPlacementTop: {
          marginBottom: '0 !important', // probably issue with MUI
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '1.5rem', // 24px
          fontWeight: 700,
          lineHeight: '140%',
        },
        h2: {
          fontSize: '1.25rem',
        },
        h3: {
          fontSize: '1.125rem',
        },
        h4: {
          fontSize: '0.875rem',
        },
        button: {
          fontSize: '1rem',
        },
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          backgroundColor: 'var(--base-black)',
          color: theme.palette.primary.light,
          textAlign: 'center',
          borderRadius: '20px',
        }),
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.light,
        }),
      },
    },
    MuiCssBaseline: {
      // 16px is default font size
      styleOverrides: `
        html {
            height: 100%;
            font-family: "Lato", "Arial", "Roboto", sans-serif;
            @media (max-height: 1000px) {
              font-size: 14px; 
            }
            @media (max-height: 850px) {
              font-size: 13px; 
            }
            @media (max-height: 760px) {
              font-size: 12px; 
            }
            @media (max-height: 670px) {
              font-size: 10px; 
            }
        }
        body {
            margin: 0;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
             'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            // background-color: var(--base-black);
        }
        button {
          border: none;
        }
        p {
          margin: 0;
        }
      `,
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: '3.5rem',
        },
      },
    },
  },
});

export function ThemeProvider(props: PropsWithChildren) {
  const { children } = props;
  return (
    <MUIThemeProvider theme={theme}>
      <MUIStylesThemeProvider theme={theme}>
        {children}
      </MUIStylesThemeProvider>
    </MUIThemeProvider>
  );
}
