import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSizeConfig } from '../../dorian-shared/getSizeConfig';
import {
  GetMultiplayerStatusResponseData,
} from '../../dorian-shared/types/multiplayerServer/GetMultiplayerStatusResponseData';
import { MultiplayerProvider } from '../../providers/MultiPlayerProvider';
import { fetchMultiplayerStatus } from './fetchMultiplayerStatus';
import { Stream } from './Stream/Stream';

export function ActiveStreamPage() {
  const [multiplayerStatus, setMultiplayerStatus] = useState<GetMultiplayerStatusResponseData>();

  const params = useParams();
  const multiplayerId = Number(params.multiplayerId);
  const isFeaturedView = Boolean(params.isFeaturedView);

  const { gameplayWidth, gameplayHeight } = getSizeConfig();

  useEffect(() => {
    fetchMultiplayerStatus(multiplayerId).then((newMultiplayerStatus) => {
      setMultiplayerStatus(newMultiplayerStatus);
    });
  }, [multiplayerId]);

  return (
    <span>
      {multiplayerStatus && (
      <MultiplayerProvider>
        <Stream
          gameplayWidth={gameplayWidth}
          gameplayHeight={gameplayHeight}
          episodeId={multiplayerStatus.episodeId}
          isFeaturedStream={isFeaturedView}
        />
      </MultiplayerProvider>
      )}
    </span>
  );
}
