export enum ScheduledEventStatus {
        NotStarted = 0,
        InProgress = 1,
        Finished = 2,
}
export interface ScheduledEventResponseData {
        eventId: number;
        storyId: string;
        episodeId: string;
        startTime: string; // Date
        timeBefore: string; // Date
        timeAfter: string; // Date
        multiplayerId: number;
        eventTitle: string;
        status: ScheduledEventStatus;
        hostUsername: string;
        avatarUrl: string;
        attendees: number;
    }
