import { SvgIcon, SvgIconProps } from '@mui/material';

/* eslint-disable max-len */
export function SoundOIffIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="" path="url(#clip0_12432_135395)">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.49368 16.2102L12.74 22.68V1L7.50958 7.4502H1.78C1.34922 7.4502 1 7.79941 1 8.2302V15.4302C1 15.861 1.34922 16.2102 1.78 16.2102H7.49368Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M15.5352 16.4698C15.5369 16.4698 15.5385 16.4698 15.5401 16.4698H15.5301C15.5318 16.4698 15.5335 16.4698 15.5352 16.4698ZM14.8301 16.1798C15.0289 16.3686 15.277 16.4685 15.5352 16.4698C15.7934 16.4686 16.0414 16.3785 16.2401 16.1798L19.1701 13.2498L22.1001 16.1798C22.2989 16.3686 22.547 16.4685 22.8052 16.4698C22.8035 16.4698 22.8018 16.4698 22.8001 16.4698H22.8101C22.8085 16.4698 22.8069 16.4698 22.8052 16.4698C23.0634 16.4686 23.3114 16.3785 23.5101 16.1798C23.9001 15.7898 23.9001 15.1598 23.5101 14.7698L20.5801 11.8398L23.5001 8.91979C23.8901 8.52979 23.8901 7.89979 23.5001 7.50979C23.1101 7.11979 22.4801 7.11979 22.0901 7.50979L19.1701 10.4298L16.2501 7.50979C15.8601 7.11979 15.2301 7.11979 14.8401 7.50979C14.4501 7.89979 14.4501 8.52979 14.8401 8.91979L17.7601 11.8398L14.8301 14.7698C14.4401 15.1598 14.4401 15.7898 14.8301 16.1798Z" />
        </g>
        <defs>
          <clipPath id="clip0_12432_135395">
            <rect width="22.81" height="21.68" transform="translate(1 1)" />
          </clipPath>
        </defs>
      </svg>

    </SvgIcon>
  );
}
