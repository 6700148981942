import axios from 'axios';
import { useEffect, useState } from 'react';
import { EpisodeInfoResponseData } from '../dorian-shared/types/multiplayerServer/EpisodeInfoResponseData';
import { StoryInfoResponseData } from '../dorian-shared/types/multiplayerServer/StoryInfoResponseData';
import { StoryVariablesResponseData } from '../dorian-shared/types/multiplayerServer/StoryVariablesResponseData';
import { RuntimeEnv, getRuntimeEnv } from '../getRuntimeEnv';

const ROOT_DOMAIN = 'test.4dorian.net';
const DEFAULT_PORPOISE_NAMESPACE = 'dev';
const PORPOISE_NAMESPACE_MATCHER = new RegExp(`^[\\w]+\\.([\\w]+)\\.${ROOT_DOMAIN.replace(/\./g, '\\.')}$`);
const porpoisePath = (sub: string, namespace: string) => `https://${sub}.${namespace}.${ROOT_DOMAIN}`;

const getPorpoiseSubdomain = (sub: string, _hostname?: string): string => {
  const namespace = PORPOISE_NAMESPACE_MATCHER
    .exec(_hostname || window?.location?.hostname || '')?.[1] || DEFAULT_PORPOISE_NAMESPACE;
  return porpoisePath(sub, namespace);
};

const engineObjectsCacheUrlByEnvLabel = {
  unstable1: 'https://dorian-review-cache.s3.us-east-2.amazonaws.com',
  unstable2: 'https://dorian-review-cache.s3.us-east-2.amazonaws.com',
  staging: 'https://dorian-review-cache.s3.us-east-2.amazonaws.com',
  tactical: 'https://dorian-tactical-bucket.s3.us-east-2.amazonaws.com',
  prod: 'https://dorian-book-covers.s3.us-east-2.amazonaws.com',
  porpoise: getPorpoiseSubdomain('data'),
};

const runtimeEnv = getRuntimeEnv();
// eslint-disable-next-line no-nested-ternary
const envLabel = runtimeEnv === RuntimeEnv.Porpoise ? 'porpoise' : runtimeEnv === RuntimeEnv.Prod ? 'prod' : 'staging';

const engineObjectsCacheUrl = engineObjectsCacheUrlByEnvLabel[envLabel];

export const s3EngineObjectsCache = axios.create({
  baseURL: `${engineObjectsCacheUrl}/v1`,
});

export const getFullURL = (path: string) => `${engineObjectsCacheUrl}${path}`;
export const getEpisodeInfo = (episodeUuid: string) => s3EngineObjectsCache.get<EpisodeInfoResponseData>(
  `/getEpisodeInfo/${episodeUuid}?t=${Date.now()}`,
);

export function useEpisodeInfo(episodeUuid: string, onError?: (error: unknown) => void) {
  const [episodeInfo, setEpisodeInfo] = useState<EpisodeInfoResponseData>();

  useEffect(() => {
    if (episodeUuid) {
      (async () => {
        try {
          const response = await getEpisodeInfo(episodeUuid);
          setEpisodeInfo(response.data);
        } catch (error) {
          if (onError) {
            onError(error);
          }
        }
      })();
    }
  }, [episodeUuid, onError]);

  return episodeInfo;
}

const getStoryInfo = (storyUuid: string) => s3EngineObjectsCache.get<StoryInfoResponseData>(
  `/getStoryInfo/${storyUuid}?t=${Date.now()}`,
);

export function useStoryInfo(storyUuid: string, onError?: (error: unknown) => void) {
  const [storyInfo, setStoryInfo] = useState<StoryInfoResponseData>();

  useEffect(() => {
    if (storyUuid) {
      (async () => {
        try {
          const response = await getStoryInfo(storyUuid);
          setStoryInfo(response.data);
        } catch (error) {
          if (onError) {
            onError(error);
          }
        }
      })();
    }
  }, [storyUuid, onError]);

  return storyInfo;
}

const getStoryVariables = (storyUuid: string) => s3EngineObjectsCache.get<StoryVariablesResponseData>(
  `/getStoryVariables/${storyUuid}?t=${Date.now()}`,
);

export function useStoryVariables(storyUuid?: string) {
  const [storyVariables, setStoryVariables] = useState<StoryVariablesResponseData>();

  useEffect(() => {
    if (storyUuid) {
      (async () => {
        const response = await getStoryVariables(storyUuid);
        setStoryVariables(response.data);
        // TODO: handle error
      })();
    }
  }, [storyUuid]);

  return storyVariables;
}
