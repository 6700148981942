import { Box, Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { ScheduledStream } from '../../dorian-shared/types/apiServer/GetEventScheduleV2ResponseData';
import { ScheduledEventStatus } from '../../dorian-shared/types/apiServer/getScheduledEventWeb/ScheduledEventResponseData';
import { useToast } from '../../providers/ToastProvider';
import { getScheduledStreamWeb } from '../../services/apiServer';
import { getFullURL, useEpisodeInfo, useStoryInfo } from '../../services/s3EngineObjectsCache';
import { DownloadButton } from '../components/DownloadButton/DownloadButton';
import { CountdownSection } from './CountdownSection/CountdownSection';
import cs from './ScheduledStreamPage.module.css';
import { StreamInfoSection } from './StreamInfoSection/StreamInfoSection';

function useStreamStatusRedirect() {
  const { showToast } = useToast();

  const navigate = useNavigate();
  const scheduledStream = useLoaderData() as ScheduledStream;

  const params = useParams();
  const { scheduledStreamId } = params;

  useEffect(() => {
    const intervalId = scheduledStreamId ? setInterval(() => {
      const beforeDate = parseISO(scheduledStream.timeBefore);
      const isTimeToGoLive = beforeDate <= new Date();

      if (isTimeToGoLive) {
        getScheduledStreamWeb(Number(scheduledStreamId)).then((scheduledStream) => {
          const { multiplayerId } = scheduledStream;
          if (multiplayerId) {
            navigate(`/streams/${multiplayerId}`);
          } else if (scheduledStream.status === ScheduledEventStatus.Finished) {
            showToast({
              text: 'This event was cancelled. We are taking you to our app, so you can discover more there.',
            });
            clearInterval(intervalId);
          }
        });
      }
    }, 10 * 1000) : undefined;
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [navigate, scheduledStream.timeBefore, scheduledStreamId, showToast]);
}

function useKeyToSyncCountdown() {
  const [key, setKey] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => setKey((key) => key + 1), 10 * 1000);
    return () => clearInterval(intervalId);
  }, []);
  return key;
}

function getCoverImageUrl(episodeInfo?: {
  bookCover: {
    thumbnail?: string
    '512x512'?: string
    unedited?: string
  }
}) {
  const path = episodeInfo?.bookCover.thumbnail
      ?? episodeInfo?.bookCover['512x512']
      ?? episodeInfo?.bookCover.unedited
      ?? '';
  return getFullURL(path);
}

export function ScheduledStreamPage() {
  const scheduledStream = useLoaderData() as ScheduledStream;
  const episodeInfo = useEpisodeInfo(scheduledStream.episodeId);
  const storyInfo = useStoryInfo(scheduledStream.storyId);
  useStreamStatusRedirect();
  const keyToSyncCountdown = useKeyToSyncCountdown();
  return (
    <div className={cs.container}>
      <Typography variant="h1" color="primary.light" pt={6} pb={3}>Upcoming Stream</Typography>
      <Typography variant="h1" p={4.5} className={cs.status}>Stream hasn't started yet</Typography>
      <Box p={3}>
        <Box mb={4}>
          <CountdownSection
            key={keyToSyncCountdown}
            startDateTime={scheduledStream.startTime}
          />
        </Box>
        <StreamInfoSection
          eventTitle={scheduledStream.eventTitle}
          storyTitle={storyInfo?.title ?? ''}
          episodeTitle={episodeInfo?.title ?? ''}
          description={episodeInfo?.description ?? ''}
          coverImageUrl={getCoverImageUrl(episodeInfo)}
          hostName={scheduledStream.hostUsername}
          hostAvatarUrl={scheduledStream.avatarUrl}
          attendeesNumber={scheduledStream.attendees}
        />
        <DownloadButton />
      </Box>
    </div>
  );
}
