import AgoraRTC, { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import { BaseParticipant } from '../../types/BaseParticipant';
import { Participant } from './Participant/Participant';
import cs from './Participants.module.css';

AgoraRTC.setLogLevel(3);

interface ParticipantsProps {
  videosByPlayerId: {
    [uid: string]: IAgoraRTCRemoteUser
  }
  participants: BaseParticipant[]
  largeVideoPlayerId?: string
}

export function Participants(props: ParticipantsProps) {
  const {
    videosByPlayerId,
    participants,
    largeVideoPlayerId,
  } = props;

  return (
    <div className={cs.container}>
      {participants.map((participant, index) => (
        <Participant
          // eslint-disable-next-line react/no-array-index-key
          key={`${participant.playerId}-${index}`}
          participant={participant}
          video={videosByPlayerId[participant.playerId]?.videoTrack}
          isLargeVideo={participant.playerId === largeVideoPlayerId}
        />
      ))}
    </div>
  );
}
