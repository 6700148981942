import { Answer } from '@dorian/gameplay-renderer/dist/models/TextBubbleUpdateData';

const placeholderRegexp = /<PLAYER_?NAME>/ig;

export const replacePlayerNamePlaceholder = (
  text: string,
  playerName: string,
) => text.replace(placeholderRegexp, playerName);

export const replacePlayerNamePlaceholderInAnswers = (
  answers: Answer[],
  playerName: string,
) => answers.map((answer) => ({
  ...answer,
  text: replacePlayerNamePlaceholder(answer.text, playerName),
}));
