import { Button, SvgIconProps, Typography } from '@mui/material';
import { SoundOIffIcon } from '../../../icons/SoundOIffIcon';
import { SoundOnIcon } from '../../../icons/SoundOnIcon';

export interface MuiMaterialProps extends SvgIconProps {
  isMuted: boolean
  onMuteChange: (value: boolean) => void
}

export function MuteButton(props: MuiMaterialProps) {
  const { isMuted, onMuteChange } = props;
  return (
    <>
      <Button onClick={() => onMuteChange(!isMuted)} sx={{ padding: 0, margin: 0 }}>
        {isMuted
          ? <SoundOIffIcon sx={{ fontSize: 24 }} />
          : <SoundOnIcon color="secondary" sx={{ fontSize: 24 }} />}
      </Button>
      <Typography color="primary.light">{isMuted ? 'Unmute' : 'Mute'}</Typography>
    </>
  );
}
